const trackingConfig = {
  checkoutOptionType: {
    selector: '.checkout-step-login__register-button',
    event: 'tracking:checkoutOption',
    data: {
      step: 2,
      option: 'register'
    }
  },
  checkoutOptionType2: {
    selector: '#checkout_guest',
    event: 'tracking:checkoutOption',
    data: {
      step: 2,
      option: 'guest'
    }
  },
  checkoutOptionType3: {
    selector: '#paypal-checkout',
    event: 'tracking:checkoutOption',
    data: {
      step: 1,
      option: 'Paypal-Express'
    }
  },
  checkoutPaypalDirectBillingPayment: {
    selector: '#paymentMethodChangeViewmodel label[for="checkbox-paypalDirectBilling-true"]',
    event: 'tracking:checkoutOption',
    data: {
      step: 5,
      option: (elm) => elm.querySelector('input[name="paypalDirectBilling"]')['value'] === 'true' ? 'PAY_PAL directBilling' : 'PAY_PAL'
    }
  },
  checkoutPayDirectDirectBillingPayment: {
    selector: '#paymentMethodChangeViewmodel label[for="checkbox-payDirectDirectBilling-true"]',
    event: 'tracking:checkoutOption',
    data: {
      step: 5,
      option: (elm) => elm.querySelector('input[name="payDirectDirectBilling"]')['value'] === 'true' ? 'PAY_DIRECT directBilling' : 'PAY_DIRECT'
    }
  },
  storeTeaserDetailClick: {
    selector: '.store-teaser__details-button',
    event: 'tracking:genericEvent',
    data: {
      event: 'filialuebersicht.klick.details',
      filialId: (elm) => elm.dataset.filialId,
    }
  },
  storeTeaserEventClick: {
    selector: '.store-teaser__events-button',
    event: 'tracking:genericEvent',
    data: {
      event: 'filialuebersicht.klick.events',
      filialId: (elm) => elm.dataset.filialId,
    }
  },
  eventTeaserBookingClick: {
    selector: '.event-list__button',
    event: 'tracking:genericEvent',
    data: {
      event: 'eventteaser.klick.buchen',
      filialId: (elm) => elm.dataset.eventId,
    }
  },
  boxSetFlagListClick: {
    selector: '.product-box .flag--boxSetUrl',
    event: 'tracking:genericEvent',
    data: {
      event: 'flags.klick.boxSet',
      wban: (elm) => elm.closest('.product-box').dataset.wban,
      position: 'list'
    }
  },
  boxSetFlagPdpClick: {
    selector: '.product-detailed-page .flag--boxSetUrl',
    event: 'tracking:genericEvent',
    data: {
      event: 'flags.klick.boxSet',
      wban: () => (document.querySelector('.product-detailed-page') as HTMLElement).dataset.wban,
      position: 'ads'
    }
  },
};

export default trackingConfig;
