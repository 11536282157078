import {
  DataLayer,
  DataLayerProduct,
} from '../../types/DataLayer';
import type TrackingProductData from '../../types/TrackingProductData';
import type TrackingPromotionData from '../../types/TrackingPromotionData';
import type TrackingVideoData from '../../types/TrackingVideoData';
import pigeon from '../../utilities/js/pigeon/pigeon';
import cookieMonster from '../../utilities/js/cookieMonster/cookieMonster';
import httpRequest from '../../utilities/js/httpRequest/httpRequest';
import jsGlobalConfig from '../../globals/jsGlobalConfig';
import {getTrackingListValue} from './getTrackingListValue';
import {dataHelper} from '../../utilities/js/dataHelper/dataHelper';

declare global {
  interface Window {
    dataLayer: DataLayer[],
    trackingConsentModeEnabled: boolean
  }
}

class TrackingService {
  static EVENT_CHECKOUT_OPTION = 'tracking:checkoutOption';
  static EVENT_PRODUCT_IMPRESSION = 'tracking:productImpression';
  static EVENT_PRODUCT_CLICK = 'tracking:productClick';
  static EVENT_PRODUCT_VIEW = 'tracking:productView';
  static EVENT_ADD_TO_CART = 'tracking:addToCart';
  static EVENT_REMOVE_FROM_CART = 'tracking:removeFromCart';
  static EVENT_PROMOTION_IMPRESSION = 'tracking:promotionImpression';
  static EVENT_PROMOTION_CLICK = 'tracking:promotionClick';
  static EVENT_COOKIE_CONSENT = 'tracking:cookieConsent';
  static EVENT_VIDEO_IMPRESSION = 'tracking:videoImpression';
  static EVENT_VIDEO_CLICK = 'tracking:videoClick';
  static EVENT_GENERIC = 'tracking:genericEvent';

  private lastProductViewWban: string = '';

  constructor() {
    window.dataLayer = window.dataLayer || [];
    pigeon.subscribe(TrackingService.EVENT_CHECKOUT_OPTION, this.checkoutOption.bind(this));
    pigeon.subscribe(TrackingService.EVENT_PRODUCT_IMPRESSION, this.productImpression.bind(this));
    pigeon.subscribe(TrackingService.EVENT_PRODUCT_CLICK, this.productImpressionClick.bind(this));
    pigeon.subscribe(TrackingService.EVENT_PRODUCT_VIEW, this.productView.bind(this));
    pigeon.subscribe(TrackingService.EVENT_ADD_TO_CART, this.addToCart.bind(this));
    pigeon.subscribe(TrackingService.EVENT_REMOVE_FROM_CART, this.removeFromBasket.bind(this));
    pigeon.subscribe(TrackingService.EVENT_PROMOTION_IMPRESSION, this.promotionImpression.bind(this));
    pigeon.subscribe(TrackingService.EVENT_PROMOTION_CLICK, this.promotionClick.bind(this));
    pigeon.subscribe(TrackingService.EVENT_COOKIE_CONSENT, this.pushUserConsensus.bind(this));
    pigeon.subscribe(TrackingService.EVENT_VIDEO_IMPRESSION, this.trackVideoImpression.bind(this));
    pigeon.subscribe(TrackingService.EVENT_VIDEO_CLICK, this.trackVideoClick.bind(this));
    pigeon.subscribe(TrackingService.EVENT_GENERIC, this.genericEvent.bind(this));
    this.pushUserConsensus(false);
  }

  static trigger(event: string, data: any) {
    pigeon.publish(event, data);
  }

  static dataLayerPush(data: any) {
    window.dataLayer.push(data);
  }

  protected addToCart(data: TrackingProductData) {
    const layer = this.productData('add_to_cart', data);
    this.track(layer);
  }

  protected productView(data: TrackingProductData) {
    // Only track new wbans, never the same twice in a row
    if (this.lastProductViewWban !== data.wban) {
      this.lastProductViewWban = data.wban;
      const finalData = {
        ...data,
        list: data.category || data.mainCategory
      }

      const layer = this.productData('view_item', finalData);
      this.track(layer);
    }
  }

  protected removeFromBasket(data: TrackingProductData) {
    const layer = this.productData('remove_from_cart', data);
    this.track(layer);
  }

  protected checkoutOption(actionFieldData: any) {
    const layer = {
      event: 'eec.checkout_option',
      ecommerce: {
        checkout_option: {
          actionField: actionFieldData,
        },
      }
    };
    this.track(layer);
  }

  protected productImpression(dataList: TrackingProductData[]) {
    const impressions = dataList.map((data) => {
      return {
        ...dataHelper.mapDataLayerProductFields(data),
        item_list_name: getTrackingListValue(data),
      };
    });
    const layer= {
      event: 'view_item_list',
      item_list_name: getTrackingListValue(dataList[0]),
      ecommerce: {
        items: impressions
      }
    }
    this.track(layer);
  }

  protected productImpressionClick(data: TrackingProductData) {
    const layer= {
      event: 'select_item',
      ecommerce: {
        items: [{
          ...dataHelper.mapDataLayerProductFields(data),
          item_list_name: getTrackingListValue(data),
        }]
      }
    }
    this.track(layer);
  }

  protected promotionImpression(data: TrackingPromotionData) {
    const layer = this.promotionData('view_promotion', data);
    this.track(layer);
  }

  protected promotionClick(data: TrackingPromotionData) {
    const layer = this.promotionData('select_promotion', data);
    this.track(layer);
  }

  protected promotionData(eventName, data) {
    const layer = {
      event: eventName,
      ecommerce: {
        creative_name: data.creative,
        promotion_id: data.id,
        promotion_name: data.name,
        items: []
      }
    };
    if (data.items) {
      layer.ecommerce.items = [{
        ...data.items[0],
        item_list_name: getTrackingListValue(data.items[0]),
        creative_name: data.creative,
        promotion_id: data.id,
        promotion_name: data.name,
      }];
    }
    return layer;
  }

  protected productData(eventName, data){
    const layer: DataLayerProduct = {
      event: eventName,
      ecommerce: {
        currency: data.currency,
        value: parseFloat(data.price),
        items: [{
          ...dataHelper.mapDataLayerProductFields(data),
          item_list_name: getTrackingListValue(data),
        }]
      }
    };
    return layer;
  }

  protected genericEvent(data: { event: string, [key: string]: any }) {
    this.track(data);
  }

  protected track(data: DataLayer) {
    data.cd6 = jsGlobalConfig().getLanguage() + '_' + jsGlobalConfig().getCountry();
    data.cd10 = 'bs';
    window.dataLayer.push(data);
  }

  protected pushUserConsensus(userConsensusSelected: boolean = true) {
    const cookieDough = cookieMonster.get('COOKIECONSENT');

    // Allererster Seitenaufruf ist im else-fall:
    if (cookieDough) {
      if (userConsensusSelected) {
        var url = new URL(window.location.href);
        url.searchParams.append('consent', cookieDough);
        httpRequest(url.href, {
          method: 'GET',
          headers: jsGlobalConfig().getCsrfTokenHeader(),
        }).then((page) => {
        });
      }

      const bakedCookie = JSON.parse(window.atob(cookieDough));

      if (bakedCookie.tracking) {
        this.trackConsent(true, {ad_storage: true, analytics_storage: true});
      } else {
        this.trackConsent(false, {ad_storage: false, analytics_storage: false});
      }

      this.track({
        event: 'cookieConsent',
        other_enabled: bakedCookie.other === true,
        tracking_enabled: bakedCookie.tracking === true,
        marketing_enabled: bakedCookie.marketing === true,
        essential_enabled: bakedCookie.essential === true
      });
    } else {
      // Nur notwendige Cookies bei allerersten Seitenaufruf:
      this.trackConsent(false, {ad_storage: false, analytics_storage: false});
      this.track({
        event: 'cookieConsent',
        other_enabled: false,
        tracking_enabled: false,
        marketing_enabled: false,
        essential_enabled: true
      });
    }
  }

  protected trackConsent(isUpdate: boolean, configObject: { 'ad_storage': boolean, 'analytics_storage': boolean }) {
    if (window.trackingConsentModeEnabled) {
      const mapAccess = function (bool) {
        return bool ? 'granted' : 'denied';
      }
      const consentSettings = {
        'ad_storage': mapAccess(configObject['ad_storage']),
        'analytics_storage': mapAccess(configObject['analytics_storage'])
      };
      const consentSettingType = isUpdate ? 'update' : 'default';

      // Google braucht hier ein 'Arguments' Objekt, deshalb muss das verschachtelt werden und kann
      // nicht einfach in ein Array.
      this.gtag('consent', consentSettingType, consentSettings);
    }
  }

  protected gtag(...args) {
    // TS erwartet hier Dinge vom Type DataLayer. Ist ein Sonderfall. Deshalb:
    // @ts-ignore
    window.dataLayer.push(arguments);
  }

  protected trackVideoImpression(data: TrackingVideoData) {
    this.track({
      event: 'video.impression',
      asset: data,
    });
  }

  protected trackVideoClick(data: TrackingVideoData) {
    this.track({
      event: 'video.click',
      asset: data,
    });
  }
}

new TrackingService();
export {TrackingService};
