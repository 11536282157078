const events = {};

const pigeon = {
  subscribe(eventName, callback) {
    if (!(eventName in events)) {
      events[eventName] = [];
    }

    events[eventName].push(callback);
  },
  publish(eventName, data) {
    if (!events[eventName]) {
      return;
    }

    for (let listener of events[eventName]) {
      listener(data);
    }
  }
};

export default pigeon;
