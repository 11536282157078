type CallbackFn = (data: any) => void;

class EventEmitter {
  private events: {[key: string]: CallbackFn[]} = {};

  subscribe(eventName: string, callback: CallbackFn) {
    if (!(eventName in this.events)) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  }

  publish(eventName: string, data = undefined) {
    if (!this.events[eventName]) {
      return;
    }

    for (let listener of this.events[eventName]) {
      listener(data);
    }
  }

  remove(eventName: string) {
    delete this.events[eventName];
  }
}

export {EventEmitter};
