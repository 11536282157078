class ProductsOrderTableSettings {
  animationName: string;
  animationDuration: string;
  availabilityInfo: {
    showStatementText: boolean,
    showStatementDate: boolean,
  }
  showGroupHeadline: boolean;
}
const productsOrderTableSettings = new ProductsOrderTableSettings();

productsOrderTableSettings.animationName = 'vue-animation--list';
productsOrderTableSettings.animationDuration = '.5s';
productsOrderTableSettings.availabilityInfo = {
  showStatementText: false,
  showStatementDate: false,
};
productsOrderTableSettings.showGroupHeadline = true;

export {productsOrderTableSettings, ProductsOrderTableSettings};
