import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["navigation__element", {
      'navigation__element--is-touch': _ctx.isTouch,
      'navigation__element--hover': _ctx.hover,
      'navigation__element--visible': _ctx.visible,
      'navigation__element--mouseout': _ctx.mouseout
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickNaviElement && _ctx.clickNaviElement(...args))),
    onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseoverElement && _ctx.mouseoverElement(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseoutElement && _ctx.mouseoutElement(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34 /* CLASS, NEED_HYDRATION */))
}