class ProductContentBlockSettings {
  readMoreHeight: number;
  productlistPageBottomWithCarousel: boolean;
}

const productContentBlockSettings = new ProductContentBlockSettings();
productContentBlockSettings.readMoreHeight = 215;
productContentBlockSettings.productlistPageBottomWithCarousel = false;

export {productContentBlockSettings, ProductContentBlockSettings};
