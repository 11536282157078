import type FlagList from '../../types/FlagList';

class ProductDetailsSettings {
  flags: {
    src: {
      boxSetImage: string,
      awardInSetImage: string,
    },
    minusText: string,
    percentText: string,
    whitelist: FlagList,
    whitelistTag: string[],
  }
  productReview: {
    formScrollTop: number,
    submitScrollTop: number,
    useRecommendationSection: boolean,
  }
  referredByHash: string
}

const productDetailsSettings = new ProductDetailsSettings();

productDetailsSettings.flags = {
  src: {
    boxSetImage: '/assets/bps/frontend-core/staticImages/wood-box.png',
    awardInSetImage: '/assets/bps/frontend-core/staticImages/awardInSet.svg'
  },
  minusText: '',
  percentText: '%',
  whitelist: ['personalized', 'free', 'new', 'newVintage', 'savings', 'award', 'stoerer', 'nachhaltigkeit', 'bio', 'vegan', 'quote', 'boxSetUrl'],
  whitelistTag: [],
};
productDetailsSettings.productReview = {
  formScrollTop: -180,
  submitScrollTop: -255,
  useRecommendationSection: true,
};
productDetailsSettings.referredByHash = '#referredBy=';

export {productDetailsSettings, ProductDetailsSettings};
