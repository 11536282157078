const dataHelper = {
  mapDataLayerProductFields(data: any) {
    let layer = {
      item_id: data.artNr,
      item_name: data.name,
      item_brand: data.producer || '',
      item_category: data.mainCategory || '',
      item_variant: data.wban,
      price: parseFloat(data.price),
      quantity: data.quantity || 1,
      index: data.position || '',
      product_country: data.country || '',
      product_region: data.region || '',
      product_bottle_size: data.volume || '',
      product_availability: data.availability || '',
      product_unit_type: data.volumeUnit || '',
      product_reduced: data.priceReduction,
      product_promotion: data.saleUnit || '',
      personalized: data.personalized || 0,
      personalizationRank: data.personalizationRank || 0,
      marketplace: data.marketplace || false
    };
    if (data.promotion) {
      layer['creative_name'] = data.promotion.creative;
      layer['promotion_id'] = data.promotion.id;
      layer['promotion_name'] = data.promotion.name;
    }
    return layer;
  }
}

export {dataHelper}
