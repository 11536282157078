<template>
  <div
    class="modal"
    :id="id"
    v-if="visible"
  >
    <div
      class="modal__header"
      :class="[headlineType ? `modal__header--${headlineType}` : '']"
      v-if="headline"
      v-html="headline"
    />
    <span
      class="modal__close-button"
      @click="close"
    />
    <div class="modal__content">
      <slot />
    </div>
  </div>
</template>

<script>
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  const Modal = {
    name: 'modal',
    props: {
      id: String,
      headline: String,
      headlineType: String,
      initialVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visible: this.initialVisible
      };
    },
    created() {
      pigeon.subscribe('modal:open', this.subscribeModalOpenHandler.bind(this));
      pigeon.subscribe('modal:close', this.subscribeModalCloseHandler.bind(this));
    },
    updated() {
      this.$nextTick(() => {
        pigeon.publish('modal:updated', {
          'visible': this.visible,
          'id': this.id
        });
      })
    },
    methods: {
      subscribeModalOpenHandler(data) {
        if (data.id === this.id) {
          this.open();
        }
      },
      subscribeModalCloseHandler(data) {
        if (data.id === this.id) {
          this.close();
        }
      },
      open() {
        pigeon.publish('pageoverlay:show');
        this.visible = true;
      },
      close() {
        pigeon.publish('pageoverlay:hide');
        this.visible = false;
      }
    }
  };

  export default Modal;
</script>
