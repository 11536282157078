const trackingPageTypeConfig = [
  {
    selector: '.page-home',
    pageType: 'home'
  }, {
    selector: '.page--category-list, .page--search-list, .category-page',
    pageType: 'category'
  }, {
    selector: '.product-detailed-page__acquisition-product-title--wrapper',
    pageType: 'aq'
  }, {
    selector: '.landing-page, .product-detail-top-landing-page',
    pageType: 'display'
  }, {
    selector: '.wishlist-page',
    pageType: 'wishlist'
  }, {
    selector: '.page-detail-view',
    pageType: 'detail'
  }, {
    selector: '.cart-page',
    pageType: 'basket'
  }, {
    selector: '.checkout-step-login, .checkout-steps, .checkout-step-confirmation',
    pageType: 'checkout'
  }, {
    selector: '.user-area, .login-page, .account-register, .request-password-page, .reset-password-page',
    pageType: 'account'
  }, {
    selector: 'body',
    pageType: 'default'
  },
];

export default trackingPageTypeConfig;
