<template>
  <div class="product-box-wide__segment-conversion">
    <span
      class="product-box-wide__button-wishlist product-box__button-wishlist"
      :class="{
        'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
        'product-box__button-wishlist--remove': !isWishlistEnabled,
        'product-box__button-wishlist--loading': state === 'loadingWishlist',
      }"
      @click.prevent="$emit('wishlistClicked')"
    />
    <div
      class="product-box-wide__product-flags product-box-wide__product-flags--conversion"
      v-if="productBoxData.flags"
    >
      <flags
        :flags="productBoxData.flags"
        :limit="1"
        :white-list="settings.flags.whitelistConversion"
      />
    </div>
    <availability-info
      v-if="showAvailability"
      class="product-box-wide__availability product-box__availability"
      :availability="productBoxData.availability"
    />
    <winemaker-price
      v-if="productBoxData.winemakerPrice"
    />
    <div class="product-box-wide__price-wrapper">
      <price-info
        v-if="!hidePrice && productBoxData.price"
        class="product-box-wide__price product-box__price"
        :value="productBoxData.price"
        :alcohol="productBoxData.alcohol"
        :show-star="true"
      />
      <product-qty-and-cart-btn
        class="product-box-wide__buttons"
        :product-data="productBoxData"
        :product-link="productLink"
        :state="state"
        :add-successor-active="addSuccessorActive"
        @quantityChange="$emit('quantityChange', $event)"
        @addToBasket="$emit('addToBasket', $event)"
        @onRequestClicked="$emit('onRequestClicked', $event)"
      />
    </div>
    <a
      v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
      :href="foodSpecificationLink"
      class="product-box__link"
      v-html="$t('detail.product.characteristics')"
    />
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import AvailabilityInfo from '../availabilityInfo/availabilityInfo.vue'
  import ProductQtyAndCartBtn from '../productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import Flags from '../flags/flags.vue'
  import type ProductBoxData from '../../types/ProductBoxData';
  import Lightbox from '../lightbox/lightbox.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';
  import WinemakerPrice from '../winemakerPrice/winemakerPrice.vue';

  @Options({
    name: 'product-box-wide-segment-conversion',
    components: {
      AvailabilityInfo,
      ProductQtyAndCartBtn,
      Flags,
      Lightbox,
      WinemakerPrice
    }
  })
  export default class ProductBoxWideSectionConversion extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;
    @Prop()
    minimumRating: string;
    @Prop({default: true})
    isWishlistEnabled: boolean;
    @Prop()
    state: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    showAvailability: boolean;
    @Prop()
    hideCounterButton: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    productLink: string;
  }
</script>
