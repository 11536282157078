import axios from 'axios';
import pigeon from '../pigeon/pigeon';
import jsGlobalConfig from '../../../globals/jsGlobalConfig';

type Method =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK';

const axiosInstance = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

axiosInstance.interceptors.response.use(
  null,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      window.logJSError('timeout', null, null, null, error);
    } else if (error.response && error.response.status === 403) {
      const timeoutErrorData = jsGlobalConfig().getSessionTimeoutData();

      pigeon.publish('globalAppMessage:show', {
        headline: timeoutErrorData.errorHead,
        message: timeoutErrorData.errorMsg,
        type: 'error'
      })

      pigeon.subscribe('pageoverlay:hide', () => {
        location.reload();
      });

      error.alreadyHandled = true;
    }


    return Promise.reject(error);
  }
);

export default axiosInstance;
export {Method}
