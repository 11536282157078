import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productClickable ? 'a' : 'span'), {
    href: _ctx.productClickable ? _ctx.product.url || _ctx.product.productUrl : null,
    class: "product-name"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.product.name), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href"]))
}