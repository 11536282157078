import JsComponent from '../jsComponent/jsComponent.js';
import {documentHelper} from '../../utilities/js/documentHelper/documentHelper';
import WeglotService from '../../services/weglot/WeglotService';

export default class WeglotPlugin extends JsComponent {
  constructor(mainElement, name = 'weglot-plugin') {
    super(mainElement, name);

    documentHelper.ready(() => {
      if ('Weglot' in window) {
        Weglot.on('languageChanged', (newLang) => {
          WeglotService.setLanguageChanged(newLang);
        });

        if (!Weglot.initialized) {
          Weglot.on('initialized', () => {
            this.setLanguageIfMismatch();
          });
        }
        else {
          this.setLanguageIfMismatch();
        }
      }
    });
  }

  setLanguageIfMismatch() {
    if (window.__globalConfig.weglotLanguage !== Weglot.getCurrentLang()) {
      WeglotService.setLanguageChanged(Weglot.getCurrentLang());
    }
  }
}
