export default class StateManager {
  static STATE_ERROR = 'error';
  static STATE_LOAD_BASKET = 'loadingBasket';
  static STATE_LOAD_WISHLIST = 'loadingWishlist';

  protected states: {id: string, state: string}[] = [];

  get(id: string): string {
    const found = this.states.filter((state) => state.id === id);
    if (found.length) {
      return found[0].state;
    }
    return '';
  }

  set(id: string, state: string) {
    this.remove(id);
    if (state) {
      this.states.push({id, state});
    }
  }

  remove(id) {
    let ndx = this.states.length;
    while (ndx--) {
      if (this.states[ndx].id === id) {
        this.states.splice(ndx, 1);
      }
    }
  }
}
