<template>
  <component
    :is="tag"
    class="ratings"
    :href="href"
    :class="{
      'ratings--text': type === 'text',
      'ratings--disabled': type === 'disabled' || type === 'text'
    }"
    :title="type === 'disabled' ? $t('detail.product.rating', [rating.toLocaleString()]) : ''"
    @click="handleRatingsClick"
  >
    <template v-if="type === 'disabled'">
      <span
        class="ratings__star"
        v-for="index in 5"
        :data-index="`star--${6 - index}`"
        :key="6 - index"
        :class="getClasses(6 - index)"
      />
    </template>
    <template v-else-if="type === 'text'">
      <span
        class="ratings__ratings-count"
        v-if="ratingsCounter > 0"
      >
        ({{ ratingsCounter }})
      </span>
      <span
        class="ratings__star"
        v-for="index in 5"
        :data-index="`star--${6 - index}`"
        :key="6 - index"
        :class="getClasses(6 - index)"
      />
    </template>
    <template v-else>
      <div class="ratings__wrapper">
        <template
          v-for="(rating, index) in ratingOptions"
          :key="`input_${name}_${rating.value}`"
        >
          <input
            class="ratings__input radio-input__input"
            type="radio"
            ref="radio"
            :name="name"
            :value="rating.value"
            @change="onChange(rating.value)"
            :id="`${name}_${rating.value}`"
          >
          <label
            class="ratings__label ratings__star radio-input"
            :class="{
              [`ratings__label--index-${5 - index}`]: true,
              'ratings__star--empty': 5 - index > updatedRating
            }"
            :for="`${name}_${rating.value}`"
          />
        </template>
        <span
          class="ratings__ratings-count"
          v-if="rating.ratingsCounter > 0"
        >
          ({{ rating.ratingsCounter }})
        </span>
      </div>
      <transition name="input-error--animation">
        <span
          class="ratings__error | input-error"
          v-if="error"
          v-html="error"
        />
      </transition>
    </template>
  </component>
</template>
<script>
  import pigeon from '../../utilities/js/pigeon/pigeon';

  const Ratings = {
    name: 'ratings',
    props: {
      rating: Number,
      ratingsCounter: Number,
      href: String,
      type: {
        type: String,
        default: 'text'
      },
      ratings: Array,
      name: String,
      required: {
        type: Boolean,
        default: false
      },
      requiredError: String,
      jumpToSite: {
        type: Boolean,
        default: false
      }
    },
    emits: ['change'],
    data() {
      return {
        updatedRating: this.rating,
        valid: null,
        error: null
      }
    },
    computed: {
      tag() {
        if (this.href) {
          return 'a';
        }
        return 'div';
      },
      ratingOptions() {
        return [...this.ratings].reverse();
      }
    },
    methods: {
      updateRating(data) {
        this.updatedRating = data;
      },

      getClasses(index) {
        const classes = {};

        if (index > this.rating) {
          classes['ratings__star--empty'] = true;
        }

        const isDecimalStar = this.rating - index > -1 && this.rating - index < 0;
        if (isDecimalStar) {
          const decimal = this.rating % 1;
          if (decimal >= 0.69) {
            classes['ratings__star--75'] = true;
          } else if (decimal >= 0.49) {
            classes['ratings__star--50'] = true;
          } else if (decimal >= 0.19) {
            classes['ratings__star--25'] = true;
          }
        }

        return classes;
      },

      onChange(value) {
        this.validate();
        this.updatedRating = value;
        if (this.valid) {
          this.$emit('change', value);
        }
      },

      validate() {
        if (this.disabled) {
          return;
        }

        let checked;
        let valid = true;
        let error = null;

        if (this.required && !this.disabled) {
          checked = this.$refs.radio.filter((input) => {
            return input.checked;
          })[0];

          if (checked) {
            valid = true;
            error = null;
          } else {
            valid = false;
            error = this.requiredError;
          }
        }

        this.valid = valid;
        this.error = error;

        return this.valid;
      },

      getValue() {
        let data = {};

        this.$refs.radio.map((input) => {
          if (input.checked) {
            data[input.name] = input.value;
          }
        });

        return data.rating;
      },

      setValue(value) {
        this.updatedRating = value;
      },

      handleRatingsClick(event) {
        if (this.href) {
          event.preventDefault();
          event.stopPropagation();
          pigeon.publish('productReview:showForm');
        }
        if (this.jumpToSite) {
          window.location.href = this.href;
        }
      },
    }
  };

  export default Ratings;
</script>
