<template>
  <lazy-load
    tag="ul"
    class="tags"
  >
    <li
      v-for="(tag) in tags"
      :key="tag.name"
      class="tags__list"
    >
      <span
        class="tags__tag | tag"
        :class="getTagClassName(tag)"
        @click="handleClick(tag)"
      >
        {{ tag.name }}
      </span>
    </li>
  </lazy-load>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import type TagType from '../../types/Tag';

  @Options({
    name: 'tags',
    components: {LazyLoad}
  })
  export default class TagsClass extends Vue {
    @Prop({required: true})
    tags: TagType[];

    getTagClassName(tag: TagType): string | null {
      if (tag.type) {
        return `tag--${tag.type.toLowerCase()}`;
      }
      return null;
    }

    handleClick(tag: TagType) {
      switch (tag.operation) {
      case 'MODIFY_QUANTITY':
        this.$emit('quantityChange', tag.info);
        break;
      case 'OPEN_URL':
        if (typeof tag.info === 'string') {
          window.open(tag.info, '_blank');
        }
        break;
      case 'OPEN_LIGHTBOX':
        if (typeof tag.info === 'string') {
          window.lightbox(tag.info);
          break;
        }
      }
    }
  }
</script>
