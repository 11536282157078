import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "winemaker-price" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "winemaker-price__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_popup = _resolveComponent("info-popup")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_info_popup, {
      "show-close-icon": true,
      position: _ctx.infoPosition
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          innerHTML: _ctx.$t('productbox.winemakerPrice.info')
        }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["position"]),
    _createTextVNode(),
    _withDirectives(_createElementVNode("span", _hoisted_3, null, 512 /* NEED_PATCH */), [
      [_directive_t, 'productbox.winemakerPrice.label']
    ])
  ]))
}