const breakpoints = {
  phone: 370,
  phoneMd: 425,
  phoneLg: 480,
  tabletSm: 600,
  tablet: 768,
  tabletLandscape: 992,
  laptop: 1024,
  laptopLg: 1200,
  desktop: 1440,
  tv: 1920
};

export default breakpoints;
