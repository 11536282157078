<template>
  <component
    :is="productLink ? 'a' : 'div'"
    :href="productLink"
    class="product-box__segment-header"
  >
    <div class="product-box__header-text">
      <span
        v-if="productBoxData.vintage"
        class="product-box__vintage"
      >
        {{ productBoxData.vintage }}
      </span>
      <span class="product-box__name">
        <span v-if="numberOfBottlesInSet">
          {{ numberOfBottlesInSet }} x
        </span>
        {{ productBoxData.description1 }}
      </span>
      <span
        class="product-box__short-description"
        v-if="productBoxData.description2"
      >
        {{ productBoxData.description2 }}
      </span>
      <span
        class="product-box__short-description"
        v-if="productBoxData.shortDescription"
      >
        {{ productBoxData.shortDescription }}
      </span>
    </div>

    <span
      class="product-box__button-wishlist"
      :class="{
        'product-box__button-wishlist--filled': isWishlistEnabled && productBoxData.inWishlist,
        'product-box__button-wishlist--remove': !isWishlistEnabled,
        'product-box__button-wishlist--loading': state === 'loadingWishlist',
      }"
      @click.prevent="$emit('wishlistClicked')"
    />
  </component>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';

  @Options({
    name: 'product-box-segment-header'
  })
  export default class ProductBoxSegmentHeader extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    state: string;
    @Prop()
    numberOfBottlesInSet: number;
    @Prop()
    productLink: string;
    @Prop({default: true})
    isWishlistEnabled: boolean;
  }
</script>
