<template>
  <ul
    class="burger-nav-tree"
    :style="{
      zIndex: level,
      minHeight: minHeight || `${parentMinHeight}px`
    }"
  >
    <template v-if="isActive">
      <li
        v-if="level > 0"
        class="burger-nav-tree__back"
        @click="goBack"
      >
        {{ $t('navigation.burger.back') }}
      </li>

      <li class="burger-nav-tree__item burger-nav-tree__item--headline">
        <a
          v-if="allCategoriesLink && allCategoriesLink.url && !allCategoriesLink.removeMenuLink"
          :href="allCategoriesLink.url"
        >
          {{ headline }}
        </a>
        <span
          v-else
        >
          {{ headline }}
        </span>
      </li>

      <template v-if="isLoading">
        <li class="burger-nav-tree__item burger-nav-tree__item--loader">
          <span class="burger-nav-tree__link">
            <span class="burger-nav-tree__link--loader">&nbsp;</span>
          </span>
        </li>
        <li class="burger-nav-tree__item burger-nav-tree__item--loader">
          <span class="burger-nav-tree__link">
            <span class="burger-nav-tree__link--loader burger-nav-tree__link--loader-alt">&nbsp;</span>
          </span>
        </li>
      </template>

      <li
        v-if="teaserSlotName"
        class="burger-nav-tree__item burger-nav-tree__item--teaser"
      >
        <slot :name="teaserSlotName" />
      </li>
      <li
        v-if="teaser && teaser.length > 1"
        class="burger-nav-tree__item burger-nav-tree__item--image-teaser"
      >
        <navigation-flyout-teaser
          v-for="(item, index) in getTeaserFromDesktop()"
          :key="`teaser-item-${index}`"
          :link-url="item.linkUrl"
          :image-src="item.imageSrc"
          :image-alt="item.imageAlt"
          :headline="item.headline"
          :subline="item.subline"
          :button-text="item.buttonText"
        />
      </li>
      <template
        v-else
      >
        <template
          v-for="(category, index) in categories"
        >
          <li
            v-if="!category.moreLink && category.name"
            :key="`${level}-${index}`"
            class="burger-nav-tree__item"
            :class="{
              'burger-nav-tree__item--icon': category.icon,
              [`burger-nav-tree__item--icon-${category.icon}`]: category.icon,
            }"
          >
            <template
              v-if="category.hasChildren || (category.teaser && category.teaser.length > 0)"
            >
              <span
                class="burger-nav-tree__link burger-nav-tree__link--arrow"
                @click="openSubcategory(index)"
              >
                {{ category.name }}
              </span>
              <burger-nav-tree
                class="burger-nav-tree__subcategories"
                :class="{
                  'burger-nav-tree__subcategories--active': activeSubcategory === index
                }"
                :is-active="activeSubcategory === index"
                :headline="category.name"
                :teaser-slot-name="category.url"
                :categories="category.subcategories"
                :teaser="category.teaser"
                :level="level + 1"
                :index="index"
                :all-categories-link="{
                  url: category.url,
                  name: $t('navigation.show_all_in_category', [category.name]),
                  removeMenuLink: category.removeMenuLink
                }"
                :parent-min-height="rootMinHeight || parentMinHeight"
                :breadcrumb-tree="breadcrumbTree"
                :is-loading="isLoading"
                @levelChange="levelChangeHandler"
              />
            </template>
            <template v-else>
              <component
                :is="category.url ? 'a' : 'span'"
                :href="category.url"
                class="burger-nav-tree__link"
                :target="category.target"
              >
                {{ category.name }}
              </component>
            </template>
          </li>
        </template>
      </template>
      <template
        v-if="moreLinks && moreLinks.length > 0"
      >
        <li
          v-for="(moreLink, index) in moreLinks"
          :key="`more-${index}`"
          class="burger-nav-tree__item burger-nav-tree__item--category-special"
        >
          <a
            :href="moreLink.url"
            class="burger-nav-tree__link"
          >
            {{ moreLink.name }}
          </a>
        </li>
      </template>
      <li
        v-else-if="allCategoriesLink && allCategoriesLink.url && !allCategoriesLink.removeMenuLink"
        class="burger-nav-tree__item burger-nav-tree__item--category-special"
      >
        <a
          :href="allCategoriesLink.url"
          class="burger-nav-tree__link"
        >
          {{ allCategoriesLink.name }}
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
  import NavigationFlyoutTeaser
    from '../../components/navigationFlyoutTeaser/navigationFlyoutTeaser.vue';

  const BurgerNavTree = {
    name: 'burger-nav-tree',
    components: {
      NavigationFlyoutTeaser
    },
    props: {
      headline: String,
      teaserSlotName: String,
      breadcrumbTree: {
        type: Array,
        default: () => []
      },
      categories: {
        type: Array,
        default: () => []
      },
      teaser: {
        type: Array,
        default: () => []
      },
      index: {
        type: Number,
        default: 0
      },
      level: {
        type: Number,
        default: 0
      },
      allCategoriesLink: Object,
      isActive: {
        type: Boolean,
        default: true
      },
      parentMinHeight: {
        type: Number,
        default: 1
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeSubcategory: -1,
        minHeight: '',
        rootMinHeight: null,
        path: [],
        breadcrumbIndex: 0,
        moreLinks: this.getMoreLinks()
      };
    },
    created() {
      // Teaser through slots feature seems already broken, but is not used by any MCS.
      // Removing the next line breaks it even more, but Vue3 won't support it.
      //this.$slots = this.$parent.$slots;
      if (this.$parent.path) {
        this.path = [...this.$parent.path, this.index];
      } else {
        this.path = [];
      }
      if (this.moreLinks.length !== this.getMoreLinks().length) {
        this.moreLinks = this.getMoreLinks();
      }
    },
    updated() {
      if (this.breadcrumbIndex < this.level) {
        this.breadcrumbIndex = this.level;
      }
      if (this.breadcrumbIndex < this.breadcrumbTree.length - 1) {
        for (let i = 0, l = this.categories.length; i < l; i += 1) {
          if (this.categories[i].name === this.breadcrumbTree[this.breadcrumbIndex]) {
            this.breadcrumbIndex += 1;
            this.openSubcategory(i);
          }
        }
      }
      if (this.moreLinks.length !== this.getMoreLinks().length) {
        this.moreLinks = this.getMoreLinks();
      }
    },
    methods: {
      getMoreLinks() {
        let moreLinks = [];
        for (let i = 0, l = this.categories.length; i < l; i += 1) {
          if (this.categories[i].moreLink) {
            moreLinks.push(this.categories[i]);
          }
        }
        return moreLinks;
      },
      openSubcategory(index) {
        const data = {
          categoryId: this.categories[index].id,
          path: [...this.path, index],
          direction: 'down',
        };
        this.$emit('levelChange', data);
        this.activeSubcategory = index;

        this.$nextTick(() => {
          this.rootMinHeight = Math.max(this.$el.offsetHeight, this.parentMinHeight);
          this.minHeight = `${this.rootMinHeight}px`;
        })
      },
      goBack() {
        this.$emit('levelChange', {
          categoryId: null,
          path: this.path,
          direction: 'up'
        });
      },
      levelChangeHandler(data) {
        const mypath = this.path || [];
        // if event came from direct child
        if (data.direction === 'up' && mypath.length + 1 === data.path.length) {
          this.activeSubcategory = -1;
        }

        // pass event to parent (recursive)
        this.$emit('levelChange', data);
      },
      getTeaserFromDesktop() {
        const nodes = document.querySelectorAll(`.navigation__element--${this.index + 1} .burger-navi-copy-from .burger-teaser .navigation-flyout-teaser`);
        const items = [];
        for (let i = 0, l = nodes.length; i < l; i += 1) {
          items.push(
            {
              linkUrl: nodes[i].getAttribute('data-linkUrl'),
              imageSrc: nodes[i].getAttribute('data-imageSrc'),
              imageAlt: nodes[i].getAttribute('data-imageAlt'),
              headline: nodes[i].getAttribute('data-headline'),
              subline: nodes[i].getAttribute('data-subline'),
              buttonText: nodes[i].getAttribute('data-buttonText')
            }
          );
        }
        return items;
      }
    }
  };

  export default BurgerNavTree;
</script>
