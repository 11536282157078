<template>
  <div class="navigation-flyout-teaser">
    <a
      class="navigation-flyout-teaser__link"
      :href="linkUrl"
    >
      <image-element
        v-if="imageSrc"
        :src="[imageSrc]"
        class="navigation-flyout-teaser__img"
        :alt="headline || subline || buttonText"
      />
      <div class="navigation-flyout-teaser__box">
        <header
          class="navigation-flyout-teaser__header"
          v-if="headline"
          v-html="headline"
        />
        <span
          class="navigation-flyout-teaser__subline"
          v-if="subline"
          v-html="subline"
        />
        <span
          class="navigation-flyout-teaser__button | button button--text button--icon-arrow-right"
          v-if="buttonText"
          v-html="buttonText"
        />
      </div>
    </a>
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import ImageElement from '../imageElement/imageElement.vue';

  @Options({
    name: 'navigation-flyout-teaser',
    components: {
      ImageElement,
      LazyLoad
    }
  })
  export default class navigationFlyoutTeaser extends Vue {
    @Prop()
    linkUrl: string;

    @Prop()
    imageSrc: string;

    @Prop()
    imageAlt: string;

    @Prop()
    headline: string;

    @Prop()
    subline: string;

    @Prop()
    buttonText: string;

    tag() {
      if (!this.linkUrl) {
        return 'DIV';
      }
      return 'a';
    }
  }
</script>
