<template>
  <div class="product-box-wide__segment-image">
    <div
      class="product-box-wide__product-flags"
      v-if="productBoxData.flags"
    >
      <flags
        :flags="productBoxData.flags"
        :limit="3"
        :award-limit="1"
        :white-list="settings.flags.whitelistImage"
      />
    </div>
    <component
      :is="productBoxData.linked ? 'a' : 'div'"
      :href="productBoxData.linked ? productBoxData.url : null"
      class="product-box-wide__image-wrapper"
      :class="{ 'product-box-wide__image-wrapper--bottle': isBoxTypeVertical }"
    >
      <div
        class="lazy-load-image product-box-wide__image"
      >
        <image-element
          img-class="lazy-load-image__image"
          :src="imgSrc"
          :title="productBoxData.images[1].title"
          :alt="productBoxData.images[1].alt"
          :webp="true"
        />
      </div>
    </component>
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../types/ProductBoxData';
  import Flags from '../flags/flags.vue';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import ImageElement from '../imageElement/imageElement.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';

  @Options({
    name: 'product-box-wide-segment-image',
    components: {
      Flags,
      LazyLoad,
      ImageElement
    }
  })
  export default class ProductBoxWideSegmentImage extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;
    @Prop()
    imgSrc: string[];
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    isBottle: boolean;
  }
</script>
