<script lang="ts">
  import {defineComponent} from 'vue';
  import {loadScript} from 'vue-plugin-load-script';

  export default defineComponent({
    name: 'ScriptLoader',
    inheritAttrs: false,

    props: {
      src: {
        type: String,
        required: true,
      },
    },

    created() {
      // eslint-disable-next-line no-console
      loadScript(this.src).catch((e) => console.error(e));
    },

    render() {
      return '';
    }
  });
</script>
