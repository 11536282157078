// IntersectionObserver Polyfill for Safari < 12.1, did not work if it's included in App.js
import 'intersection-observer';

import diContainer from '../diContainer/diContainer';

type IntersectionCallbackFn = (element: Element) => void;
type CallbackEntry = { callbackFn: IntersectionCallbackFn, element: Element };

class IntersectionService {
  protected observerDict: {[key: string]: IntersectionObserver};
  protected callbackDict: {[key: string]: CallbackEntry[]};
  protected isEnabled: boolean;
  protected eventListeners: {};
  protected testMode = false;

  constructor() {
    this.observerDict = {};
    this.callbackDict = {};
    this.isEnabled = true;
    this.eventListeners = {};
    this.testMode = diContainer.get('env') === 'test';
  }

  createObserver(id: string, observerOptions: IntersectionObserverInit): IntersectionObserver {
    if (!(id in this.observerDict)) {
      this.observerDict[id] = new IntersectionObserver(this.intersectionCallback.bind(this), observerOptions);
      this.callbackDict[id] = [];
    }
    return this.observerDict[id];
  }

  intersectionCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    if (!this.isEnabled) {
      return;
    }

    this.fireEvent('onIntersectionLoopStart');

    const id = this.getIdByObserver(observer);
    if (!id) {
      return;
    }

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;
        observer.unobserve(element);

        const callbacks = this.callbackDict[id];
        let ndx = callbacks.length;
        while (ndx--) {
          if (callbacks[ndx].element === element) {
            callbacks[ndx].callbackFn(element);
            callbacks.splice(ndx, 1);
            break;
          }
        }
      }
    });

    this.fireEvent('onIntersectionLoopEnd');
  }

  observeElement(id: string, element: Element, callbackFn: IntersectionCallbackFn) {
    if (this.testMode || window.location.hash) {
      callbackFn(element);
      return;
    }
    this.observerDict[id].observe(element);
    this.callbackDict[id].push({
      element,
      callbackFn
    });
  }

  enable() {
    this.isEnabled = true;
  }

  disable() {
    this.isEnabled = false;
  }

  activateAll(id: string) {
    const callbacks = this.callbackDict[id];
    let ndx = callbacks.length;
    while (ndx--) {
      callbacks[ndx].callbackFn(callbacks[ndx].element);
    }
    this.callbackDict[id] = [];
  }

  activateElement(id: string, container: Element) {
    const callbacks = this.callbackDict[id];
    let ndx = callbacks.length;
    while (ndx--) {
      if (container.contains(callbacks[ndx].element)) {
        callbacks[ndx].callbackFn(callbacks[ndx].element);
      }
    }
  }

  protected getIdByObserver(observer): string|null {
    for (const key in this.observerDict) {
      if (this.observerDict[key] === observer) {
        return key;
      }
    }

    return null;
  }

  addEventListener(eventName, callback) {
    if (!(eventName in this.eventListeners)) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(callback);
  }

  protected fireEvent(eventName, data = null) {
    if (!this.eventListeners[eventName]) {
      return;
    }
    for (const listener of this.eventListeners[eventName]) {
      listener(data);
    }
  }
}

const intersectionService = new IntersectionService();
export {intersectionService};
