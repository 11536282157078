import {helpers} from '@vuelidate/validators';

const regex = function (param: RegExp) {
  return helpers.withParams(
    {type: 'regex', param},
    (value: string|number) => {
      //console.log('regex', value, param)
      return !helpers.req(value) || value.toString().match(param) !== null
    }
  );
}

const minAge = function (age: number) {
  return helpers.withParams(
    {minAge: age},
    (value: string) => {
    const min = age || 18;
    return !helpers.req(value) || getAge(value) >= Number(min);
  });
}

const maxAge = function (age: number) {
  return helpers.withParams(
    {maxAge: age},
    (value: string) => {
      const max = age || 100;
      return !helpers.req(value) || getAge(value) < Number(max);
    }
  );
}

const serverSide = function (fieldName: string) {
  return helpers.withParams(
    {type: 'serverSide'},
    (value: any, siblings, vm) => {
      const error = vm.serverSideErrors.find((error: { fieldName: string; }) => error.fieldName === fieldName);
      return {
          $valid: !error,
          param: fieldName
      };
    }
  )
}

const minDate = function (min: string) {
  return helpers.withParams(
    {type: 'minDate', minDate: min},
    (value: string) => {
      if (!value) return true;
      const valDate = parseDate(value);
      const minDate = parseDate(min);
      return valDate >= minDate;
    }
  );
}

const maxDate = function (max: string) {
  return helpers.withParams(
    {type: 'maxDate', maxDate: max},
    (value: string) => {
      if (!value) return true;
      const valDate = parseDate(value);
      const maxDate = parseDate(max);
      return valDate <= maxDate;
    }
  );
}

const excluded = function (excludedValues: string[]) {
  return helpers.withParams(
    {type: 'excluded'},
    (value: string) => {
      if (!value) return true;
      return !excludedValues.includes(value);
    }
  );
}

const notPackageStore = function() {
  return helpers.withParams(
    {type: 'notPackageStore'},
    (value: string = '') => {
      const val = value?.toLowerCase() || '';
      let wrongText = null;
      if (val.indexOf('packstation') >= 0) {
        wrongText = 'Packstation';
      } else if (val.indexOf('paketstation') >= 0) {
        wrongText = 'Paketstation';
      } else if (val.indexOf('postfach') >= 0) {
        wrongText = 'Postfach';
      } else if (val.indexOf('postnummer') >= 0) {
        wrongText = 'Postnummer';
      } else if (val.indexOf('postfiliale') >= 0) {
        wrongText = 'Postfiliale';
      }

      return {
        $valid: !wrongText,
        param: wrongText
      };
  });
}

const noSpecialCharMultiline = (pattern: RegExp) => {
  return helpers.withParams(
    {type: 'noSpecialCharMultiline'},
    (value: string) => {
      if (!value) return true;

      let firstOpenHtmlTag: number = value.indexOf('<');
      let lastCloseHtmlTag: number = value.lastIndexOf('>');
      if (firstOpenHtmlTag != -1 && lastCloseHtmlTag != -1 && firstOpenHtmlTag <= lastCloseHtmlTag) {
        return false;
      }
      return pattern.test(value);
    }
  );
};

function maxTabLength(length: number) {
  return helpers.withParams(
    {},
    (value: string) => {
      const tabs = value.split(/\n/);
      return tabs && (tabs.length <= length);
    });
}

function minValue(min: number) {
  return helpers.withParams(
    {type: 'minValue'},
    (value: number) => {
        return value >= min;
    }
  );
}

function maxValue(max: number) {
  return helpers.withParams(
    {type: 'maxValue'},
    (value: number) => {
        return value <= max;
    }
  );
}

function iban(value: string) {
  if (!value) return true;

  const pattern = new RegExp('^$|[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}');
  return pattern.test(value.replace(/ /g, ''));
}

function bic(value: string) {
  if (!value) return true;

  const pattern = new RegExp('^$|([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)');
  return pattern.test(value);
}

function milesAndMoreCardNumber(value: string|number) {
    let prefixOk: boolean = true;
    const val = value.toString();
    let isnum = /^\d+$/.test(val);
    if (val.length == 15) {
        const availablePatterns = ['33303', '22201', '99222', '99200', '99990'];
        const cardBeginning = val.substring(0, 5);
        prefixOk = availablePatterns.some(pattern => cardBeginning === pattern);
    }
    return isnum && prefixOk && (val.length == 15 || val.length == 9)
}

// helpers
function getAge(value: string) {
  const today = new Date();
  const birthDate = parseDate(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function parseDate(date: string): Date {
  const dateArray = date
    .split('.')
    .map(dateElement => {
      return parseInt(dateElement);
    });
  return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
}

export {
  serverSide,
  regex,
  minAge,
  maxAge,
  minDate,
  maxDate,
  minValue,
  maxValue,
  excluded,
  notPackageStore,
  noSpecialCharMultiline,
  maxTabLength,
  iban,
  bic,
  milesAndMoreCardNumber,
}
