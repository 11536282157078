import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["products-list", _ctx.className])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (product, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "products-list__element products-list__element--type_product",
        key: product.itemId
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productBoxComponent), {
          class: "products-list__product-box",
          "product-box-data": product,
          "hide-counter-button": false,
          state: _ctx.getProductState(product),
          "tracking-additional": {
          position: index,
          list: _ctx.trackingList
        },
          onAddToBasket: _ctx.addToBasketHandler,
          onQuantityChange: _ctx.quantityChangeHandler,
          onAddToWishlist: _ctx.addToWishlistHandler,
          onRemoveFromWishlist: _ctx.removeFromWishlistHandler
        }, null, 40 /* PROPS, NEED_HYDRATION */, ["product-box-data", "state", "tracking-additional", "onAddToBasket", "onQuantityChange", "onAddToWishlist", "onRemoveFromWishlist"]))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}