import {defineAsyncComponent} from 'vue';
import '../../bps/components/browser/browser.js';

// JS COMPONENTS ---------------------------------------------------------------
import PageOverlay from '../../bps/components/pageOverlay/pageOverlay.js';
import Header from '../../bps/components/header/header.js';
import UserShortcut from '../../bps/components/userShortcut/userShortcut.js';
import GoTop from '../../bps/components/goTop/goTop.js';
import ScrollTo from '../../bps/components/scrollTo/scrollTo.js';
import Sidebar from '../../bps/components/sidebar/sidebar.js';
import StopEnter from '../../bps/components/stopEnter/stopEnter.js';
import WeglotPlugin from '../../bps/components/weglotPlugin/weglotPlugin.js';
// VUE COMPONENTS -------------------------------------------------------------
import BurgerMenu from '../../bps/components/burgerMenu/burgerMenu.vue';
import SearchAutosuggest from '../../bps/components/searchAutosuggest/searchAutosuggest.vue';
import ProductBox from '../../bps/components/productBox/productBox.vue';
import ProductBoxList from '../../bps/components/productBoxList/productBoxList.vue';
import LazyLoadImage from '../../bps/components/lazyLoadImage/lazyLoadImage.vue';
import GlobalAppMessage from '../../bps/components/globalAppMessage/globalAppMessage.vue';
import Flags from '../../bps/components/flags/flags.vue';
import NavigationElement from '../../bps/components/navigation/navigationElement.vue';
import ReadMore from '../../bps/components/readMore/readMore.vue';
import LazyLoad from '../../bps/components/lazyLoad/lazyLoad.vue';
import ImageElement from '../../bps/components/imageElement/imageElement.vue';
import PriceInfo from '../../bps/components/priceInfo/priceInfo.vue';
import ProductBoxSegmentReview from '../../bps/components/productBox/ProductBoxSegmentReview.vue'
import ProductBoxSegmentHeader from './productBox/ProductBoxSegmentHeader.vue'
import ProductBoxSegmentImage from '../../bps/components/productBox/ProductBoxSegmentImage.vue'
import ProductBoxSegmentMain from '../../bps/components/productBox/ProductBoxSegmentMain.vue'
import ProductBoxWideSegmentCenter from './productBoxWide/ProductBoxWideSegmentCenter.vue';
import ProductBoxWideSegmentImage
  from '../../bps/components/productBoxWide/ProductBoxWideSegmentImage.vue';
import ProductBoxWideSegmentConversion
  from '../../bps/components/productBoxWide/ProductBoxWideSegmentConversion.vue';
import TrustedShopBadge from '../../bps/components/trustedShopBadge/trustedShopBadge.vue';
import ProductName from '../../bps/components/productName/productName.vue';
import CounterInput from '../../bps/components/counterInput/counterInput.vue';
import Lightbox from '../../bps/components/lightbox/lightbox.vue';
import Modal from '../../bps/components/modal/modal.vue';

const JsComponents = [
  {name: 'page-overlay', constructor: PageOverlay},
  {name: 'header', constructor: Header},
  // {name: 'collapsible', constructor: Collapsible},
  {name: 'user-shortcut', constructor: UserShortcut},
  {name: 'go-top', constructor: GoTop},
  {name: 'scroll-to', constructor: ScrollTo},
  {name: 'sidebar', constructor: Sidebar},
  {name: 'stop-enter', constructor: StopEnter},
  {name: 'weglot-plugin', constructor: WeglotPlugin},
  {
    name: 'account-single-order',
    constructor: () => import(/* webpackChunkName: "account" */ '../../bps/components/accountSingleOrder/accountSingleOrder.js')
  },
  {
    name: 'account-addresses',
    constructor: () => import(/* webpackChunkName: "accountAddresses" */ '../../bps/components/accountAddresses/accountAddresses.js')
  },
  {
    name: 'checkout-step-address',
    constructor: () => import(/* webpackChunkName: "checkoutAddress" */ '../../bps/components/checkout/checkoutAddress.js')
  },
  {
    name: 'content-index--origin',
    constructor: () => import(/* webpackChunkName: "contentIndexOrigin" */ '../../bps/components/contentIndex/contentIndexOrigin.js')
  },
  {
    name: 'products-list-page',
    constructor: () => import(/* webpackChunkName: "productList" */ '../../bps/components/productsListPage/productsListPage.js')
  },
  {
    name: 'filters',
    constructor: () => import(/* webpackChunkName: "filter" */ '../../bps/components/filters/filters.js')
  },
  {
    name: 'product-detailed-page',
    constructor: () => import(/* webpackChunkName: "detail" */ '../../bps/components/productDetailedPage/productDetailedPage.js')
  },
  {
    name: 'story-teaser',
    constructor: () => import(/* webpackChunkName: "story-teaser" */ '../../bps/components/storyTeaser/storyTeaser.js')
  },
  {
    name: 'campaign-header',
    constructor: () => import(/* webpackChunkName: "campaignHeader" */ '../../bps/components/campaignHeader/campaignHeader.js')
  },
  {
    name: 'cart-page',
    constructor: () => import(/* webpackChunkName: "checkout" */ '../../bps/components/cartPage/cartPageLegacy.js')
  },
  {
    name: 'voucher-modal',
    style: () => import(/* webpackChunkName: "voucher-modal" */ './voucherModal/voucherModal.scss')
  },
];

// Global Vue components
const VueGlobalComponents = [
  {name: 'CounterInput', constructor: CounterInput},
  {name: 'PriceInfo', constructor: PriceInfo},
  {name: 'ProductBoxSegmentReview', constructor: ProductBoxSegmentReview},
  {name: 'ProductBoxSegmentHeader', constructor: ProductBoxSegmentHeader},
  {name: 'ProductBoxSegmentImage', constructor: ProductBoxSegmentImage},
  {name: 'ProductBoxSegmentMain', constructor: ProductBoxSegmentMain},
  {name: 'ProductBoxWideSegmentCenter', constructor: ProductBoxWideSegmentCenter},
  {name: 'ProductBoxWideSegmentConversion', constructor: ProductBoxWideSegmentConversion},
  {name: 'ProductBoxWideSegmentImage', constructor: ProductBoxWideSegmentImage},
  {name: 'ProductName', constructor: ProductName},
  {
    name: 'pagination',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "pagination" */ '../../bps/components/pagination/pagination.vue'))
  },
  {
    name: 'product-box-slider',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productBoxSlider" */ '../../bps/components/productBoxSlider/productBoxSlider.vue'))
  },
  {
    name: 'sale-box-segment-head',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './saleBox/SaleBoxSegmentHead.vue'))
  },
  {
    name: 'price-info-oldest',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/priceInfo/priceInfoOldest.vue'))
  },
  {
    name: 'ContactFormInputs',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName "contactForm" */ '../../bps/components/contactForm/contactFormInputs.vue'))
  },
  {
    name: 'wishlist-dialog',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "product" */ '../../bps/components/wishlistDialog/wishlistDialog.vue'))
  },
]

// Vue components usable in jsp
const VueComponents = [
  {name: 'burger-menu', constructor: BurgerMenu},
  {name: 'search-autosuggest', constructor: SearchAutosuggest},
  {name: 'product-box', constructor: ProductBox},
  {name: 'lazy-load-image', constructor: LazyLoadImage},
  {name: 'global-app-message', constructor: GlobalAppMessage},
  {name: 'read-more', constructor: ReadMore},
  {name: 'lazy-load', constructor: LazyLoad},
  {name: 'image-element', constructor: ImageElement},
  {name: 'trusted-shop-badge', constructor: TrustedShopBadge},
  {name: 'product-box-list', constructor: ProductBoxList},
  {name: 'flags', constructor: Flags},
  {name: 'navigation-element', constructor: NavigationElement},
  {name: 'lightbox', constructor: Lightbox},
  {name: 'modal', constructor: Modal},

  // Components
  {
    name: 'carousel',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "carousel" */ '../../bps/components/carousel/carousel.vue')),
  },
  {
    name: 'carousel-steps',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "carouselSteps" */ '../../bps/components/carouselSteps/carouselSteps.scss')
        import(/* webpackChunkName: "carouselSteps" */ '../../bps/components/carouselSteps/carouselSteps.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'trusted-shop-review-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "thankYouPage" */ './trustedShopReviewButton/TrustedShopReviewButton.vue'))
  },
  {
    name: 'teasergroup-teaser-slider',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "teasergroup-slide" */ '../../bps/components/teaserGroupTeaser/teaserGroupTeaserSlider.vue'))
  },
  {
    name: 'teasergroup-teaser-coverflow',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "teasergroup-coverf" */ '../../bps/components/teaserGroupTeaser/teaserGroupTeaserCoverflow.vue'))
  },
  {
    name: 'tabs',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "teasergroup-tabs" */ '../../bps/components/tab/Tabs.vue'))
  },
  {
    name: 'tab',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "teasergroup-tabs" */ '../../bps/components/tab/Tab.vue'))
  },
  {
    name: 'favorite-wine-teaser-select',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "favoriteWine" */ '../../bps/components/favoriteWineTeaser/favoriteWineTeaserSelect.vue')),
  },
  {
    name: 'winefinder',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "winefinder" */ './winefinder/winefinder.scss')
        import(/* webpackChunkName: "winefinder" */ '../../bps/components/winefinder/winefinder.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'direct-order-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "direct-order-teaser" */ './directOrderTeaser/directOrderTeaser.scss')
        import(/* webpackChunkName: "directOrder" */ '../../bps/components/directOrderTeaser/directOrderTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'billing-download-button',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "billingDownloadButton" */ './billingDownloadButton/billingDownloadButton.scss')
        import(/* webpackChunkName: "billingDownloadButton" */ '../../bps/components/billingDownloadButton/billingDownloadButton.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'product-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "productTeaser" */ './productTeaser/productTeaser.scss'),
        import(/* webpackChunkName: "productTeaser" */ '../../bps/components/productTeaser/productTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'product-teaser-slider',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productTeaserSlider" */ '../../bps/components/productTeaserSlider/productTeaserSlider.vue'))
  },
  {
    name: 'product-teaser-wide',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "productTeaserWide" */ './productTeaserWide/productTeaserWide.scss'),
        import(/* webpackChunkName: "productTeaserWide" */ '../../bps/components/productTeaserWide/productTeaserWide.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'content-index',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "contentIndex" */ '../../bps/components/contentIndex/contentIndex.vue'))
  },
  {
    name: 'content-index-header',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "contentIndex" */ '../../bps/components/contentIndexHeader/contentIndexHeader.vue'))
  },
  {
    name: 'newsletter-teaser',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "newsletterTeaser" */ './newsletterTeaser/newsletterTeaser.vue')),
  },
  {
    name: 'video-hover',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "videoHover" */ '../../bps/components/videoHover/videoHover.scss')
        import(/* webpackChunkName: "videoHover" */ '../../bps/components/videoHover/videoHover.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'video-player',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "video" */ '../../bps/components/videoPlayer/videoPlayer.vue')),
  },
  {
    name: 'similar-wines-suggest-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "similarWinesSuggestTeaser" */ './similarWinesSuggestTeaser/similarWinesSuggestTeaserInclude.scss')
        import(/* webpackChunkName: "similarWinesSuggestTeaser" */'../../bps/components/similarWinesSuggestTeaser/similarWinesSuggestTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'affiliate-order-teaser-lottery',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "affiliateOrderTeaserLottery" */ './affiliateOrderTeaserLottery/affiliateOrderTeaserLotteryIncludes.scss')
        import(/* webpackChunkName: "affiliateOrderTeaserLottery" */ '../../bps/components/affiliateOrderTeaserLottery/affiliateOrderTeaserLottery.vue')
          .then((component) => resolve(component))
      })
    })
  },


  {
    name: 'sidebar-menu',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "sidebarMenu" */ '../../bps/components/sidebarMenu/SidebarMenu.vue'))
  },
  {
    name: 'add-to-cart-layer-small',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "addToCartM" */ '../../bps/components/addToCartLayer/addToCartLayerSmall.vue'))
  },
  {
    name: 'add-to-cart-layer',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "addToCartD" */ '../../bps/components/addToCartLayer/addToCartLayer.vue'))
  },
  {
    name: 'add-to-cart-panel',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "addToCart" */ './addToCartPanel/addToCartPanel.scss')
        import(/* webpackChunkName: "addToCart" */ '../../bps/components/addToCartPanel/addToCartPanel.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'accordion',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accordion" */ '../../bps/components/accordion/accordion.vue'))
  },
  {
    name: 'page-social-media',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "socialMedia" */ '../../bps/components/socialMedia/pageSocialMedia.vue'))
  },
  {
    name: 'info-popup',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "info" */ '../../bps/components/infoPopup/infoPopup.vue'))
  },

  // Account Components
  {
    name: 'account-single-order-position',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '../../bps/components/accountSingleOrderPosition/accountSingleOrderPosition.vue'))
  },
  {
    name: 'account-single-order-reorder-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '../../bps/components/accountSingleOrderReorderButton/accountSingleOrderReorderButton.vue'))
  },
  {
    name: 'single-order-cancel',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '../../bps/components/singleOrderCancel/singleOrderCancel.vue'))
  },

  // Wishlist Components
  {
    name: 'wishlist-edit',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "wishlist" */ '../../bps/components/wishlistEdit/wishlistEdit.vue'))
  },
  {
    name: 'wishlist-intro',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "wishlist" */ '../../bps/components/wishlistIntro/wishlistIntro'))
  },
  {
    name: 'app-deal-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "nativeApp" */ '../../bps/components/appDealTeaser/appDealTeaser.scss')
        import(/* webpackChunkName: "nativeApp" */ '../../bps/components/appDealTeaser/appDealTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },

  {
    name: 'clipboard-text',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "clipbboard" */ '../../bps/components/clipboardText/clipboardText.vue'))
  },

  // Product list Components
  {
    name: 'products-list',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/productsList/productsList.vue'))
  },
  {
    name: 'products-sort',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/productsSort/productsSort.vue'))
  },
  {
    name: 'product-list-pagination',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/pagination/productListPagination.vue'))
  },
  {
    name: 'inspiration-tabs',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/inspirationTabs/inspirationTabs.vue'))
  },
  {
    name: 'active-filters',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "activeFilters" */ './activeFilters/activeFilters.scss')
        import(/* webpackChunkName: "activeFilters" */ '../../bps/components/activeFilters/activeFilters.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'filter-category',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "filter" */ '../../bps/components/filterCategory/filterCategory.vue'))
  },
  {
    name: 'filter-vue',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "filter" */ '../../bps/components/filter/filter.vue'))
  },
  {
    name: 'filter-price',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "filterPrice" */ '../../bps/components/filterPrice/filterPrice.vue'))
  },
  {
    name: 'filter-range',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "filterRange" */ '../../bps/components/filterRange/filterRange.vue'))
  },
  {
    name: 'filter-buttons',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "filter" */ '../../bps/components/filterButtons/filterButtons.vue'))
  },
  {
    name: 'product-list-content-block',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/productListContentBlock/productListContentBlock.vue'))
  },
  {
    name: 'scroll-to-content-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/scrollToContentButton/scrollToContentButton.vue'))
  },
  {
    name: 'tree-navigation',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productList" */ '../../bps/components/treeNavigation/treeNavigation.vue'))
  },
  {
    name: 'dropdown-navigation',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "dropdownNavigation" */ './dropdownNavigation/dropdownNavigation.scss')
        import(/* webpackChunkName: "dropdownNavigation" */ '../../bps/components/dropdownNavigation/dropdownNavigation.vue')
          .then((component) => resolve(component))
      })
    })
  },

  // Product detail Components
  {
    name: 'sale-box',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/saleBox/saleBox.vue'))
  },
  {
    name: 'product-navigation',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productNavigation/productNavigation.vue'))
  },
  {
    name: 'product-features',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './productFeatures/productFeatures.vue'))
  },
  {
    name: 'product-features-list',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productFeaturesList/productFeaturesList.vue'))
  },
  {
    name: 'product-quickfacts',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productQuickFacts/productQuickfacts.vue'))
  },
  {
    name: 'product-contained-in-set',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productContainedInSet/productContainedInSet.vue'))
  },
  {
    name: 'product-review-helpful',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productReviewHelpful/productReviewHelpful.vue'))
  },
  {
    name: 'product-awards-list',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './productAwardsList/productAwardsList.vue'))
  },
  {
    name: 'product-reviews-list-pagination',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/pagination/productReviewsListPagination.vue'))
  },
  {
    name: 'product-description-text',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './productDescriptionText/productDescriptionText.vue'))
  },
  {
    name: 'product-awards-headline',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productAwardsHeadline/productAwardsHeadline.vue'))
  },
  {
    name: 'product-gallery',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productGallery/productGallery.vue'))
  },
  {
    name: 'photo-swipe-gallery',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/photoSwipeGallery/photoSwipeGallery.vue'))
  },
  {
    name: 'product-origin',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productOrigin/productOrigin.vue'))
  },
  {
    name: 'product-brandline-content',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productBrandlineContent/productBrandlineContent.vue'))
  },
  {
    name: 'product-producer',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productProducer/productProducer.vue'))
  },
  {
    name: 'product-grape',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productGrape/productGrape.vue'))
  },
  {
    name: 'product-review-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "productReviewForm" */ '../../bps/components/productReviewForm/productReviewForm.vue'))
  },
  {
    name: 'goto-url-pagination',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "magazin" */ '../../bps/components/pagination/gotoUrlPagination.vue'))
  },
  {
    name: 'product-flags',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productFlags/productFlags.vue'))
  },
  {
    name: 'product-tags',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './productTags/productTags.vue'))
  },
  {
    name: 'product-subscription',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productSubscription/productSubscription.vue'))
  },
  {
    name: 'product-box-set-list',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ './productBoxSetList/productBoxSetList.vue'))
  },
  {
    name: 'product-awards-show-less-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/productAwards/productAwardsShowLessButton.vue'))
  },
  {
    name: 'product-social-media',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/socialMedia/productSocialMedia.vue'))
  },
  {
    name: 'ratings',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detail" */ '../../bps/components/ratings/ratings.vue'))
  },
  {
    name: 'product-alternatives',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "detailLazy" */'../../bps/components/productAlternatives/productAlternatives.vue')),
  },
  {
    name: 'landing-page-sale-bar',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/landingPageSaleBar/landingPageSaleBar.scss')
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/landingPageSaleBar/landingPageSaleBar.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'one-page-checkout-form',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/onePageCheckoutForm/onePageCheckoutFormIncludes.scss')
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/onePageCheckoutForm/onePageCheckoutForm.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'product-image-wallpaper',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/productImageWallpaper/productImageWallpaper.scss')
        import(/* webpackChunkName: "landingpage" */ '../../bps/components/productImageWallpaper/productImageWallpaper.vue')
          .then((component) => resolve(component))
      })
    })
  },


  // Checkout Components
  {
    name: 'products-order-table',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/productsOrderTable/productsOrderTable.vue'))
  },
  {
    name: 'products-order-table-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/productsOrderTable/productsOrderTableLegacy.vue'))
  },
  {
    name: 'greeting-card-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/greetingCardForm/greetingCardForm.vue'))
  },
  {
    name: 'greeting-card-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/greetingCardForm/greetingCardForm.vue'))
  },
  {
    name: 'shipping-info',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/shippingInfo/shippingInfo.vue'))
  },
  {
    name: 'product-order-free-items',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/productOrderFreeItems/productOrderFreeItems.vue'))
  },
  {
    name: 'delivery-forecast-message-wrapper',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/deliveryForecastMessageList/deliveryForecastMessageWrapper.vue'))
  },
  {
    name: 'order-summary-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/orderSummary/orderSummaryLegacy.vue'))
  },
  {
    name: 'order-summary',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/orderSummary/orderSummary.vue'))
  },
  {
    name: 'order-summary-small',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/orderSummarySmall/orderSummarySmall.vue'))
  },
  {
    name: 'order-summary-small-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/orderSummarySmall/orderSummarySmallLegacy.vue'))
  },
  {
    name: 'basket-form-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketFormButton/basketFormButton.vue'))
  },
  {
    name: 'basket-general-message',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketGeneralMessage/basketGeneralMessage.vue'))
  },
  {
    name: 'basket-general-message-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketGeneralMessage/basketGeneralMessageLegacy.vue'))
  },
  {
    name: 'general-message',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/generalMessage/generalMessage.vue'))
  },
  {
    name: 'delivery-forecast-message-list',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/deliveryForecastMessageList/deliveryForecastMessageList.vue'))
  },
  {
    name: 'delivery-forecast-message-list-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/deliveryForecastMessageList/deliveryForecastMessageListLegacy.vue'))
  },
  {
    name: 'payment-method-message',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/paymentMethodMessage/paymentMethodMessage.vue'))
  },
  {
    name: 'basket-cross-selling',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketCrossSelling/basketCrossSelling.vue'))
  },
  {
    name: 'basket-cross-selling-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketCrossSelling/basketCrossSellingLegacy.vue'))
  },
  {
    name: 'sovendus',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/sovendus/sovendus.vue'))
  },
  {
    name: 'basket-informative-benefits',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/basketInformativeBenefits/basketInformativeBenefits.vue'))
  },
  {
    name: 'cart-page',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/cartPage/cartPage.vue'))
  },
  {
    name: 'welcome-page',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/welcomePage/welcomePage.vue'))
  },
  {
    name: 'checkout-step-address',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/checkoutStepAddress/checkoutStepAddress.vue'))
  },
  {
    name: 'checkout-step-summary',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/checkoutStepSummary/checkoutStepSummary.vue'))
  },
  {
    name: 'sticky-sidebar', // only in use on checkout-step delivery/payment page. In all other places it's imported in the page component.
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkout" */ '../../bps/components/stickySidebar/stickySidebar.vue'))
  },

  // Form Components
  {
    name: 'radio-input',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "form" */ '../../bps/components/radioInput/radioInput.vue'))
  },
  {
    name: 'text-input',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "form" */ '../../bps/components/input/TextInput.vue'))
  },
  {
    name: 'form-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "form" */ '../../bps/components/formButton/formButton.vue'))
  },
  {
    name: 'register-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "registerForm" */ '../../bps/components/registerForm/registerFormLegacy.vue'))
  },
  {
    name: 'login-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "loginForm" */ '../../bps/components/loginForm/loginForm.vue'))
  },
  {
    name: 'request-password-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "requestPasswordForm" */ '../../bps/components/requestPasswordForm/requestPasswordForm.vue'))
  },
  {
    name: 'request-password-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "requestPasswordForm" */ '../../bps/components/requestPasswordForm/requestPasswordFormLegacy.vue'))
  },
  {
    name: 'reset-password-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "resetPasswordForm" */ '../../bps/components/resetPasswordForm/resetPasswordForm.vue'))
  },
  {
    name: 'reset-password-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "resetPasswordForm" */ '../../bps/components/resetPasswordForm/resetPasswordFormLegacy.vue'))
  },
  {
    name: 'account-remove-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accountRemoveForm" */ '../../bps/components/accountRemoveView/accountRemoveForm.vue'))
  },
  {
    name: 'newsletter-page-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "newsletterPageForm" */ '../../bps/components/newsletterPage/newsletterPageForm.vue'))
  },
  {
    name: 'account-newsletter-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accountNewsletterForm" */ '../../bps/components/accountNewsletter/accountNewsletterForm.vue'))
  },
  {
    name: 'account-settings-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accountSettingsForm" */ '../../bps/components/accountSettings/accountSettingsForm.vue'))
  },
  {
    name: 'account-email-change-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accountEmailChangeForm" */ '../../bps/components/accountEmailChangeView/accountEmailChangeForm.vue'))
  },
  {
    name: 'payment-method-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "paymentMethodForm" */ '../../bps/components/paymentMethodForm/paymentMethodForm.vue'))
  },
  {
    name: 'payment-method-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "paymentMethodForm" */ '../../bps/components/paymentMethodForm/paymentMethodFormLegacy.vue'))
  },
  {
    name: 'account-password-change-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "accountPasswordChangeForm" */ '../../bps/components/accountPasswordChangeView/accountPasswordChangeForm.vue'))
  },
  {
    name: 'address-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "addressForm" */ '../../bps/components/addressForm/addressFormLegacy.vue'))
  },
  {
    name: 'checkout-step-next-button',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutStepNextButton" */ '../../bps/components/checkoutStepNextButton/checkoutStepNextButton.vue'))
  },
  {
    name: 'checkout-errors',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutErrors" */ '../../bps/components/checkoutErrors/checkoutErrors.vue'))
  },
  {
    name: 'delivery-options-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "deliveryOptionsForm" */ '../../bps/components/deliveryOptionsForm/deliveryOptionsForm.vue'))
  },
  {
    name: 'delivery-options-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "deliveryOptionsForm" */ '../../bps/components/deliveryOptionsForm/deliveryOptionsFormLegacy.vue'))
  },
  {
    name: 'voucher-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "voucherForm" */ '../../bps/components/voucherForm/voucherForm.vue'))
  },
  {
    name: 'voucher-form-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "voucherForm" */ '../../bps/components/voucherForm/voucherFormLegacy.vue'))
  },
  {
    name: 'contact-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "contactForm" */ '../../bps/components/contactForm/contactForm.vue'))
  },
  {
    name: 'checkout-step-summary-payment-express',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutStepSummaryPaymentExpress" */ '../../bps/components/checkoutStepSummary/checkoutStepSummaryPaymentExpress.vue'))
  },
  {
    name: 'checkout-step-summary-payment-express-legacy',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutStepSummaryPaymentExpress" */ '../../bps/components/checkoutStepSummary/checkoutStepSummaryPaymentExpressLegacy.vue'))
  },
  {
    name: 'checkout-step-summary-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutStepSummaryForm" */ '../../bps/components/checkoutStepSummary/checkoutStepSummaryForm.vue'))
  },
  {
    name: 'unsubscribe-newsletter-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "unsubscribeNewsletterForm" */ '../../bps/components/unsubscribeNewsletterForm/unsubscribeNewsletterForm.vue'))
  },
  {
    name: 'account-username-change-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "account" */ '../../bps/components/accountUsernameChangeView/accountUsernameChangeForm.vue'))
  },

  // ParcelLab
  {
    name: 'parcel-lab-script',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "parcellab" */ '../../bps/components/parcellab/parcellab.vue'))
  },
  // Emarsys
  {
    name: 'emarsys-opt-out',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "emarsys" */ '../../bps/components/emarsysOptOut/emarsysOptOut.vue'))
  },
  // Cookies
  {
    name: 'consent-cookie-box',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "cookieBox" */ '../../bps/components/consentCookieBox/consentCookieBox.vue'))
  },
  {
    name: 'consent-cookie-box-light-box',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "cookieBox" */ '../../bps/components/consentCookieBox/consentCookieBoxLightBox.vue'))
  },
  {
    name: 'consent-cookie-reset',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "cookieBoxReset" */ '../../bps/components/consentCookieReset/consentCookieReset.vue')),
  },
  // Google Maps
  // {
  //   name: 'google-map',
  //   constructor: defineAsyncComponent(() => import(/* webpackChunkName: "gmap" */ '../../bps/components/googleMap/googleMap.vue'))
  // },
  // {
  //   name: 'google-map-marker',
  //   constructor: defineAsyncComponent(() => import(/* webpackChunkName: "googlemaps" */ '../../bps/components/googleMap/googleMapMarker.vue'))
  // },
  // {
  //   name: 'google-map-polygon',
  //   constructor: defineAsyncComponent(() => import(/* webpackChunkName: "googlemaps" */ '../../bps/components/googleMap/googleMapPolygon.vue'))
  // },
  {
    name: 'availability-reminder',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "availabilityReminder" */ '../../bps/components/availabilityReminder/availabilityReminder.vue'))
  },
  {
    name: 'deployment-infos',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "deploymentInfos" */ './deploymentInfos/deploymentInfosInclude.scss')
        import(/* webpackChunkName: "deploymentInfos" */ '../../bps/components/deploymentInfos/deploymentInfos.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'script-loader',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "scriptLoader" */ '../../bps/components/scriptLoader/scriptLoader.vue'))
  },
  {
    name: 'content-cinema-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "contentCinemaTeaser" */ './contentCinemaTeaser/contentCinemaTeaser.scss')
        import(/* webpackChunkName: "contentCinemaTeaser" */ '../../bps/components/contentCinemaTeaser/contentCinemaTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },
  {
    name: 'refer-a-friend-reward-form',
    constructor: defineAsyncComponent(() => import(/* webpackChunkName: "referAFriendReward" */ '../../bps/components/referAFriendReward/referAFriendRewardForm.vue'))
  },


  // Admin Components
  {
    name: 'admin-pss-editor-form',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "adminService" */ '../../bps/components/adminService/adminPssEditorForm.scss')
        import(/* webpackChunkName: "adminService" */ '../../bps/components/adminService/adminPssEditorForm.vue')
          .then((component) => resolve(component))
      })
    })
  },

  //SlotMachine
  {
    name: 'slot-machine-teaser',
    constructor: defineAsyncComponent(() => {
      return new Promise((resolve) => {
        import(/* webpackChunkName: "slotMachineTeaser" */ './slotMachineTeaser/slotMachineTeaser.scss')
        import(/* webpackChunkName: "slotMachineTeaser" */ '../../bps/components/slotMachineTeaser/SlotMachineTeaser.vue')
          .then((component) => resolve(component))
      })
    })
  },
];

export {JsComponents, VueComponents, VueGlobalComponents};

