import {EventEmitter} from '../eventEmitter/EventEmitter';

const eventEmitter = new EventEmitter();

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    eventEmitter.publish('ready');
    eventEmitter.remove('ready');
  }
}

const documentHelper = {
  ready(callback: () => void) {
    eventEmitter.subscribe('ready', callback);
  }
}

export {documentHelper};
