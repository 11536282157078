import {reactive} from 'vue';
import {CategoryTree} from '../../types/Navigation';

class NavigationStore {
  currentPath: number[] = [];
  navigationTree: CategoryTree[] = [];
}

const navigationStore = new NavigationStore();
export default reactive(navigationStore);
