const openTagRegex = /<\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)?>/g;
const closeTagRegex = /<\/\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)?>/g;
const tagNameRegex = /<\/?([^\s>]+)(\s|>)/;
const partialTagRegex = /<[^>]*(<|$)/;

function excerpt(text: string, numOfWords: number = 40, ellipsis: string = '...'): string {
  const arr = text.split(' ');
  let result = arr.slice(0, numOfWords).join(' ');

  // treat html text
  if (result.match(tagNameRegex)) {
    if (result.match(partialTagRegex)) {
      result = text.substr(0, result.lastIndexOf('<')).trim();
    }

    result += (arr.length > numOfWords ? ellipsis : '');

    const openedTagsResult = result.match(openTagRegex);
    const closedTagsResult = result.match(closeTagRegex);
    const openedTags = openedTagsResult ? openedTagsResult.map(tag => tag.match(tagNameRegex)[1]) : [];
    const closedTags = closedTagsResult ? closedTagsResult.map(tag => tag.match(tagNameRegex)[1]) : [];
    if (openedTags.length !== closedTags.length) {
      result += getClosingTags(openedTags, closedTags);
    }
  } else {
    result += (arr.length > numOfWords ? ellipsis : '');
  }

  return result;
}

function getClosingTags(openedTags, closedTags) {
  const resultArr = [];
  const unclosedTags = [...openedTags];
  closedTags.forEach(tagName => {
    unclosedTags.splice(unclosedTags.indexOf(tagName), 1);
  });

  unclosedTags.reverse().forEach(tagName => {
    resultArr.push(`</${tagName}>`);
  });

  return resultArr.join('');
}

export {excerpt};
