<template>
  <div class="winemaker-price">
    <info-popup
      :show-close-icon="true"
      :position="infoPosition"
    >
      <div
        v-html="$t('productbox.winemakerPrice.info')"
      />
    </info-popup>
    <span
      class="winemaker-price__label"
      v-t="'productbox.winemakerPrice.label'"
    />
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import InfoPopup from '../infoPopup/infoPopup.vue';

  @Options({
    name: 'winemaker-price',
    components: {InfoPopup},
  })
  export default class WinemakerPrice extends Vue {
    @Prop({default: 'box-right'})
    infoPosition: string;
  }
</script>
