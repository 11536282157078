import {email, maxLength, minLength, sameAs, url} from '@vuelidate/validators';
import {maxAge, minAge, notPackageStore, regex, noSpecialCharMultiline, iban, bic} from './validators';

const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð .'-/(/)\\?/!#:;_§$%\\=\\+\\*´`º°½¼¾–řỳş|&€]+$/;
const dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
const noSpecialChar = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð .'-/(/)\\?/!#:;_§$%\\=\\+\\*‚´`‘’º°½¼¾–řỳş|&€]+$/;
const noSpecialCharMultiLineRegex = new RegExp('^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõőøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð .\'-/(/)\\?/!#:;_§$%\\=\\+\\*‚´`‘’º°½¼¾–řỳş|&€\\r\\n"„⹂“”©®™«»•·¨…¡¿¦ª¯\u00AD¶†‡¢£¤¥ƒ±÷×√∫¹²³¸‰µ¬<>]+$');

const minAgeValue = 18;
const maxAgeValue = 100;

const ValidationFieldConfig = {
  email: {
    email,
    min: minLength(5),
    max: maxLength(80),
  },
  password: {
    max: maxLength(200),
    min: minLength(6)
  },
  name: {
    max: maxLength(40),
    alpha_spaces: regex(nameRegex)
  },
  birthdate: {
    minAge: minAge(minAgeValue),
    maxAge: maxAge(maxAgeValue),
    date_format: regex(dateRegex),
  },
  date: {
    regex: regex(dateRegex),
  },
  username: {
    regex: regex(nameRegex),
    min: minLength(1),
    max: maxLength(45)
  },
  ratingAuthor: {
    max: maxLength(100),
    regex: regex(noSpecialChar)
  },
  ratingTitle: {
    max: maxLength(500),
    regex: regex(noSpecialChar)
  },
  noSpecialCharMultiline: {
    noSpecialCharMultiline: noSpecialCharMultiline(noSpecialCharMultiLineRegex)
  },
  shortText: {
    min: minLength(2),
    regex: regex(noSpecialChar)
  },
  longText: {
    noSpecialCharMultiline: noSpecialCharMultiline(noSpecialCharMultiLineRegex)
  },
  company: {
    max: maxLength(50),
  },
  vatIn: {
    regex: regex(/^[A-Z]{1,4}[0-9]{1,13}$/)
  },
  careOf: {
    max: maxLength(40),
    regex: regex(noSpecialChar),
    packageStore: notPackageStore()
  },
  phoneNumber: {
    max: maxLength(30),
    regex: regex(/^$|^[0-9\-+ ]+$/)
  },
  city: {
    max: maxLength(60),
    regex: regex(noSpecialChar),
  },
  cityGer: {
    max: maxLength(30),
    noSpecialChar: regex(noSpecialChar),
    regex: regex(/^[A-ZÄÖÜa-zäöüß\s/().-]+$/)
  },
  street: {
    max: maxLength(60),
    regex: regex(noSpecialChar),
    packageStore: notPackageStore()
  },
  streetNumber: {
    max: maxLength(9),
    regex: regex(noSpecialChar),
  },
  packstationNumber: {
    min: minLength(3),
    max: maxLength(10),
    numeric: regex(/^[0-9]+$/),
    regex: regex(noSpecialChar)
  },
  postNumber: {
    min: minLength(6),
    max: maxLength(10),
    numeric: regex(/^[0-9]+$/),
    regex: regex(noSpecialChar)
  },
  postOfficeNumber: {
    min: minLength(3),
    max: maxLength(10),
    numeric: regex(/^[0-9]+$/),
    regex: regex(noSpecialChar)
  },
  myPost24UserId: {
    min: minLength(6),
    max: maxLength(35),
    regex: regex(noSpecialChar)
  },
  pickPostUserId: {
    min: minLength(6),
    max: maxLength(35),
    regex: regex(noSpecialChar)
  },
  myPost24Address: {
    max: maxLength(60),
    regex: regex(noSpecialChar)
  },
  pickPostAddress: {
    max: maxLength(60),
    regex: regex(noSpecialChar)
  },
  debitName: {
    min: minLength(2),
    max: maxLength(60),
    regex: regex(noSpecialChar),
  },
  iban: {
    iban
  },
  bic: {
    bic
  },
  contactContent: {
    min: minLength(2),
    noSpecialCharMultiline: noSpecialCharMultiline(noSpecialCharMultiLineRegex)
  },
  url: {
    max: maxLength(255),
    url: url
  },
  checkbox: {
    checked: sameAs(true),
  },

  none: {}
};

type ValidationFieldConfigKey = keyof typeof ValidationFieldConfig;
const ValidationValues = {
  minAgeValue,
  maxAgeValue,
}

export {
  ValidationFieldConfig,
  ValidationFieldConfigKey,
  ValidationValues,
};
