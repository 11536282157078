<script lang="ts">
  import {defineComponent, h, compile} from 'vue';

  export default defineComponent({
    name: 'DynamicTemplate',

    inheritAttrs: false,

    props: {
      html: {
        type: String,
        required: true
      }
    },

    render() {
      if (this.html) {
        return h(compile(this.html, {}).bind(this))
      }
      return h('div', 'Loading...')
    }
  });
</script>
