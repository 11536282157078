import httpRequest from '../../utilities/js/httpRequest/httpRequest'

let service;

class SearchSuggestions {
  constructor() {
    this.apiUrls = {
      searchForQuery: window.__globalConfig.apiUrls.searchSuggestions.searchForQuery,
      searchProductsForQuery: window.__globalConfig.apiUrls.searchSuggestions.searchProductsForQuery,
      searchSimilarWines: window.__globalConfig.apiUrls.searchSuggestions.searchSimilarWines
    }
  }

  searchForQuery(query, scope) {
    return httpRequest(`${this.getApiUrl(scope)}?q=${query}`)
      .then(response => {
        return this.mapResponseData(response.data);
      })
  }

  searchSimilarWines(productNr, limit) {
    return new Promise(resolve =>
      httpRequest(this.apiUrls.searchSimilarWines + productNr + '/' + limit + '/').then(resolve));
  }

  mapResponseData(data) {
    const newData = [];

    for (let type in data.suggestions) {
      newData.push({
        type: type,
        suggestions: data.suggestions[type]
      });
    }

    return newData.filter(result => !!result.suggestions.length);
  }

  getApiUrl(scope) {
    if (scope === 'similar') {
      return this.apiUrls.searchProductsForQuery;
    }
    return this.apiUrls.searchForQuery;
  }
}

function searchSuggestions() {
  if (service) {
    return service;
  } else {
    service = new SearchSuggestions();
    return service;
  }
}

export default searchSuggestions;
