import httpRequest from '../../utilities/js/httpRequest/httpRequest';
import encodeFormData from '../../utilities/js/encodeFormData/encodeFormData';
import jsGlobalConfig from '../../globals/jsGlobalConfig';
import pigeon from '../../utilities/js/pigeon/pigeon.js';
import tracking from '../../utilities/js/tracking/Tracking';
import type AddToBasketResponse from '../../types/AddToBasketResponse';
import ProductBoxData from '../../types/ProductBoxData';
import type WishlistMetaResponse from '../../types/WishlistMetaResponse';
import type RecurringOrderFrequency from '../../types/RecurringOrderFrequency';
import Product from '../../types/Product';
import {getTrackingListValue} from '../tracking/getTrackingListValue';
import {AxiosPromise} from 'axios';

class ProductService {
  protected urls: {
    addToBasket: string,
    addToWishlist: string,
    removeFromWishlist: string,
    changeQuantity: string,
  };

  constructor() {
    this.urls = window.__globalConfig.apiUrls.product;
  }

  addToBasket(product: Product|ProductBoxData, quantity: number, trackingList: string, recurringOrderFrequency?: RecurringOrderFrequency): Promise<{ data: AddToBasketResponse }> {
    let formData = jsGlobalConfig().getCsrfTokenData();
    formData['itemId'] = product.itemId;
    formData['productId'] = product.id;
    formData['quantity'] = quantity;
    formData['wishlist'] = false;
    formData['title'] = document.title;
    if ('trackingData' in product) {
      formData['trackingList'] = getTrackingListValue({
        ...product.trackingData,
        list: trackingList
      });
    } else {
      formData['trackingList'] = trackingList;
    }

    if (window.location.hash.indexOf('#referredBy=') != -1) {
      formData['referredBy'] = window.location.hash.replace('#referredBy=', '');
    }

    if ('categoryId' in product) {
      formData['categoryId'] = product.categoryId;
    }

    if (recurringOrderFrequency) {
      formData['recurringOrderFrequency'] = recurringOrderFrequency;
    }

    if (product.soldoutRelation && product.soldoutRelation.itemId) {
      formData['referredBy'] = ('wban' in  product ? product.wban : '') || ('trackingData' in product ? product.trackingData.wban : '');
      formData['itemId'] = product.soldoutRelation.itemId;
    }

    return httpRequest(this.urls.addToBasket, {
      method: 'POST',
      data: encodeFormData(formData)
    }).then(response => {
      pigeon.publish('productaddedToCart:updateCounter', response.data.count);
      if (response.data.count > 0) {
        pigeon.publish('basket:change', {isEmpty: false});
      }
      tracking.fireAddToCart(response.data.trackingData);
      return response;
    });
  }

  addToWishlist(productId: string, itemId: string, trackingList: string): Promise<{ data: WishlistMetaResponse }> {
    let wishlistUrl = this.urls.addToWishlist;
    wishlistUrl = wishlistUrl.concat(`?productId=${productId}&itemId=${itemId}&trackingList=${encodeURI(trackingList)}/`);
    return httpRequest(wishlistUrl)
      .then((response) => {
        if (response.data.showDialog && response.data.wishlist) {
          // Open wishlist dialog in lightbox
          const wishlistData = response.data.wishlist;
          pigeon.publish(
            'lightbox:showContent',
            `<wishlist-dialog
                  :initial-data='${JSON.stringify(wishlistData).replace(/'/g, '&#039;').replace(/&quot;/g, '&#039;')}'
                  :product-data='${JSON.stringify({id: productId, itemId})}'
                  :close-fn="({pigeon}) => pigeon.publish('lightbox:close')"
                />`
          );
          pigeon.publish('lightbox:setClass', ['lightbox--wishlist-dialog']);
        }
        // TODO: BPS:3107 - we need wishlist counter in order show it in the mobile app
        pigeon.publish('productaddedToWishlist:updateCounter', 1);
        return response;
      });
  }

  removeFromWishlist(productId: string, itemId: string, trackingList: string): Promise<{ data: WishlistMetaResponse }> {
    if (!jsGlobalConfig().isWishlistPopupEnabled()) {
      location.href = jsGlobalConfig().getWishlistUrl();
      return null;
    }

    const url = this.urls.removeFromWishlist + `/position/${productId}/${itemId}/?trackingList=${trackingList}`;
    return httpRequest(url)
      .then((response) => {
        if (response.data.showDialog && response.data.inWishlist && response.data.wishlist) {
          // Open wishlist dialog in lightbox
          const wishlistData = response.data.wishlist;
          pigeon.publish(
            'lightbox:showContent',
            `<wishlist-dialog
                  :initial-data='${JSON.stringify(wishlistData).replace(/'/g, '&#039;').replace(/&quot;/g, '&#039;')}'
                  :product-data='${JSON.stringify({id: productId, itemId})}'
                  :close-fn="({pigeon}) => pigeon.publish('lightbox:close')"
                />`
          );
          pigeon.publish('lightbox:setClass', ['lightbox--wishlist-dialog']);

          // TODO sometime: check if there are items on any wishlist and remove wishlist badge from header
          // TODO: BPS:3107 - we need wishlist counter in order show it in the mobile app
          //pigeon.publish('productaddedToWishlist:updateCounter', 0);
        }

        return response;
      });
  }

  changeQuantity(productId: string, itemId: string, quantity: number): Promise<{ data: ProductBoxData }> {
    const givenUrl = this.urls.changeQuantity;
    let url;
    if (givenUrl.includes('?productId')) {
      url = givenUrl.substring(0, givenUrl.indexOf('?'));
    } else {
      url = givenUrl;
    }
    url += `?productId=${productId}&itemId=${itemId}&selectedQuantity=${quantity}`;
    return httpRequest.get(url)
      .then(response => {
        return response;
      });
  }

  addToBasketFromOrder(orderId: string, trackingList = '', title = ''): Promise<{ data: AddToBasketResponse[] }> {
    const url = `/ajax/basket/add/fromOrder/${orderId}/?trackingList=${trackingList}&title=${title}`
    return httpRequest.get(url)
      .then(response => {
        if (response.data.length > 0) {
          pigeon.publish('productaddedToCart:updateCounter', response.data[0].count);
          response.data.forEach((orderResponse: AddToBasketResponse) => {
            tracking.fireAddToCart(orderResponse.trackingData);
          });
        }

        return response;
      });
  }

  getProductAlternatives(articleNo: string): AxiosPromise<{productBoxData: ProductBoxData[], recommendationDataInfo: Record<string, string>, hasMore: boolean | null}> {
    const url = `/p/alternatives/?articleNo=${articleNo}`;
    return httpRequest.get(url);
  }

  getThirdPartyProductAlternatives(articleNo: string): AxiosPromise<{productBoxData: ProductBoxData[], recommendationDataInfo: Record<string, string>, hasMore: boolean | null}> {
    const url = `/p/alternatives/thirdPartySupplier/?articleNo=${articleNo}`;
    return httpRequest.get(url);
  }
}

const productService = new ProductService();
export default productService;
