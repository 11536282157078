import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "price-info__old"
}
const _hoisted_2 = { class: "price-info__old-text" }
const _hoisted_3 = { class: "price-info__old-value" }
const _hoisted_4 = { class: "price-info__current" }
const _hoisted_5 = { class: "price-info__value" }
const _hoisted_6 = { class: "price-info__currency" }
const _hoisted_7 = {
  key: 0,
  class: "price-info__asterisk"
}
const _hoisted_8 = { class: "price-info__unit" }
const _hoisted_9 = { class: "price-info__unit-text" }
const _hoisted_10 = {
  key: 0,
  class: "price-info__unit-price"
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["price-info", {
      'price-info--reduction': _ctx.value.old,
      [`price-info--currency-${_ctx.value.currency}`]: true
    }])
      }, [
        (_ctx.value.old)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('price.instead_of')), 1 /* TEXT */),
              _createTextVNode(),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.value.old), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createTextVNode(),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.value.currentValue), 1 /* TEXT */),
          _createTextVNode(),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.value.currency), 1 /* TEXT */),
          _createTextVNode(),
          (_ctx.showStar)
            ? (_openBlock(), _createElementBlock("sup", _hoisted_7, "\n        *\n      "))
            : _createCommentVNode("v-if", true)
        ]),
        _createTextVNode(),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getUnitText()), 1 /* TEXT */),
          _createTextVNode(),
          (_ctx.value.unitPrice)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.value.unitPrice), 1 /* TEXT */),
                (_ctx.value.unit)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("/" + _toDisplayString(_ctx.value.unit), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createTextVNode(),
        (_ctx.showTax)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "price-info__tax",
              innerHTML: _ctx.$t(_ctx.showShippingIncluded ? _ctx.messageKey.deliveryInclShip : _ctx.messageKey.deliveryInclVat, [_ctx.shippingInfoUrl])
            }, null, 8 /* PROPS */, _hoisted_11))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}