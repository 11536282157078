<template>
  <div
    class="lightbox"
    :class="additionalClasses"
    v-if="isOpen"
    v-outside-click:[true]="closeOutside"
  >
    <div
      class="lightbox__close-btn"
      v-if="showCloseButton"
      @click="close('closeButton')"
    />
    <div
      class="lightbox__container"
    >
      <dynamic-template
        v-if="inlineContent"
        :html="inlineContent"
      />
      <slot />
    </div>
  </div>
</template>


<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import httpRequest from '../../utilities/js/httpRequest/httpRequest';
  import * as LightboxQueue from '../../utilities/js/lightboxQueue/lightboxQueue';
  import pigeon from '../../utilities/js/pigeon/pigeon';
  import DynamicTemplate from '../dynamicTemplate/dynamicTemplate.vue';

  declare global {
    interface Window { lightbox: Function; lightboxClose: Function;  }
  }

  // Global function, accessible from everywhere (<a href="javascript:lightbox('https://someurl', 'lightbox--class')">)
  window.lightbox = (url, additionalClasses, lightboxEvent = 'lightbox:showUrl') => {
    pigeon.publish(lightboxEvent, url);
    additionalClasses && pigeon.publish('lightbox:setClass', additionalClasses);
  };

  window.lightboxClose = () => {
    pigeon.publish('lightbox:close');
  };

  @Options({
    name: 'lightbox',
    components: {DynamicTemplate}
  })
  export default class Lightbox extends Vue {
    @Prop()
    contentUrl: string;

    @Prop({type: Boolean, default: false})
    initialOpened: boolean;

    @Prop({type: Boolean, default: false})
    eventListener: boolean;

    additionalClasses: string[] = [];
    inlineContent: string = ''; // vue template
    isOpen = false;
    showCloseButton = true;

    mounted() {
      if (this.eventListener) {
        pigeon.subscribe('lightbox:showUrl', this.showUrl);
        pigeon.subscribe('lightbox:hideClose', this.hideClose);
        pigeon.subscribe('lightbox:showContent', this.showContent);
        pigeon.subscribe('lightbox:close', this.close);
        pigeon.subscribe('lightbox:setClass', this.setAdditionalClasses);
      }

      if (this.initialOpened) {
        // wait a second until events are subscribed in pageOverlay.js
        setTimeout(() => {
          if (this.contentUrl) {
            this.showUrl(this.contentUrl);
          } else {
            this.open();
          }
        }, 1000);
      }
    }

    showUrl(url: string = null) {
      this.open();
      this.inlineContent = '<div class="loading"></div>';

      httpRequest.get(url).then((response) => {
        if (response) {
          this.inlineContent = response.data;
        } else {
          this.close();
        }
      });
    }

    hideClose() {
      this.showCloseButton = false;
    }

    closeOutside() {
      if (this.showCloseButton) {
        this.close();
      }
    }

    showContent(content: string) {
      this.inlineContent = content;
      this.open();
    }

    open() {
      if (LightboxQueue.pageHasActiveLightbox()) {
        LightboxQueue.addToQueue(this);
        return;
      }

      pigeon.publish('pageoverlay:show');
      this.isOpen = true;
      this.lockWindow(true);
    }

    close(closeReason = '') {
      pigeon.publish('pageoverlay:hide');
      this.inlineContent = '';
      this.isOpen = false;
      this.lockWindow(false);
      this.additionalClasses = [];
      this.$emit('close', closeReason);

      this.$nextTick(() => {
        LightboxQueue.onLightboxClose();
      });
    }

    lockWindow(lock: boolean) {
      if (lock) {
        pigeon.publish('pageoverlay:lock');
      } else {
        pigeon.publish('pageoverlay:unlock');
      }
    }

    setAdditionalClasses(classes: string[]) {
      this.additionalClasses = classes;
    }
  }
</script>

