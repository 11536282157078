<template>
  <form
    class="search-autosuggest"
    :class="{ 'search-autosuggest--focus': focus }"
    ref="form"
    :action="action"
    @submit.prevent="submitForm"
    @click.stop
  >
    <div class="input search-autosuggest__input">
      <label
        class="input__label input__label--as-placeholder"
        :class="{
          'input__label--hide-label': searchModel.search
        }"
        v-if="placeholder"
        v-t="placeholder"
      />
      <div class="input__wrapper">
        <input
          type="text"
          name="q"
          :placeholder="placeholder"
          autocomplete="off"
          autocorrect="off"
          class="input__field"
          inputmode="search"
          v-model="searchModel.search"
          @keyup="inputKeyUpHandler"
          @focus="focus = true"
          @blur="focus = false"
        >
      </div>
    </div>
    <div
      class="input__button-clear"
      :class="{
        'input__button-clear--active' : searchModel.search
      }"
      @click="buttonClearClickHandler"
    />
    <div
      class="input__button input__button--icon_search"
      @click="buttonClickHandler"
    />
    <input
      v-for="(input, index) in hiddenInputs"
      type="hidden"
      :name="input.name"
      :value="input.value"
      :key="index"
    >
    <div
      class="search-autosuggest__suggestions-flyout"
      v-if="suggestions.length && this.showSuggestions"
    >
      <div
        v-if="suggestions && suggestions.length > 1"
        class="search-autosuggest__suggestions-list-wrapper"
      >
        <div
          class="search-autosuggest__suggestions-list--left"
        >
          <suggestions-list-item
            v-for="(suggestionsList, listIndex) in otherSuggestions"
            :key="listIndex"
            :suggestion="suggestions"
            :suggestion-item="suggestionsList"
            :list-index="listIndex"
            :active-list-index="activeListIndex"
            :active-suggestion-index="activeSuggestionIndex"
            :choose-suggestion="chooseSuggestion"
          />
        </div>
        <div
          class="search-autosuggest__suggestions-list--right"
        >
          <suggestions-list-item
            :suggestion="suggestions"
            :suggestion-item="suggestions[suggestions.length - 1]"
            :list-index="suggestions.length - 1"
            :active-list-index="activeListIndex"
            :active-suggestion-index="activeSuggestionIndex"
            :choose-suggestion="chooseSuggestion"
          />
          <template
            v-if="suggestions.length > 0"
          >
            <a
              class="search-autosuggest__suggestion search-autosuggest__suggestion--link"
              @click="submitForm"
              v-html="$t('search.suggest.allResults.label')"
              href="#"
            />
          </template>
        </div>
      </div>
      <div
        v-else
        class="search-autosuggest__suggestions-list-wrapper search-autosuggest__suggestions-list--full"
      >
        <suggestions-list-item
          v-for="(suggestionsList, listIndex) in suggestions"
          :key="listIndex"
          :suggestion="suggestions"
          :suggestion-item="suggestionsList"
          :list-index="listIndex"
          :active-list-index="activeListIndex"
          :active-suggestion-index="activeSuggestionIndex"
          :choose-suggestion="chooseSuggestion"
        />
        <template
          v-if="suggestions.length > 0"
        >
          <a
            class="search-autosuggest__suggestion search-autosuggest__suggestion--link"
            @click="submitForm"
            v-html="$t('search.suggest.allResults.label')"
            href="#"
          />
        </template>
      </div>
    </div>
    <!-- notice: form submit can not be prevented, if there is only 1 input field present -->
    <input
      aria-hidden="true"
      class="search-autosuggest__hidden"
      type="text"
    >
    <div
      class="search-autosuggest__close"
      @click="hideSuggestions"
    />
  </form>
</template>

<script>
  import TextInput from '../../../bps/components/input/TextInput.vue';
  import searchSuggestions from '../../services/searchSuggestions/searchSuggestions.js';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';
  import SuggestionsListItem from './suggestionsListItem';

  const SearchAutosuggest = {
    name: 'search-autosuggest',
    components: {
      TextInput,
      SuggestionsListItem
    },
    props: {
      hiddenInputs: {
        type: Array,
        default() {
          return [];
        }
      },
      placeholder: {
        type: String,
        default: '',
      },
      action: String,
      initialValue: String,
      name: String,
      scope: String,
      showPageOverlay: {
        type: Boolean,
        default: false
      },
      disableSuggestions: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        searchModel: {
          search: ''
        },
        showSuggestions: false,
        previousQuery: '',
        pendingRequest: null,
        suggestions: [],
        activeListIndex: -1,
        activeSuggestionIndex: -1,
        focus: false
      };
    },
    computed: {
      otherSuggestions() {
        return this.suggestions.slice(0, this.suggestions.length - 1);
      }
    },
    watch: {
      showSuggestions: function (newState, oldState) {
        if (this.showPageOverlay) {
          pigeon.publish(`pageoverlay:${newState === true ? 'show' : 'hide'}`, 'page-overlay--search');
        }
      }
    },
    mounted() {
      document.body.addEventListener('click', this.hideSuggestions.bind(this));
    },
    methods: {
      inputFocusInHandler() {
        this.showSuggestions = !!this.suggestions.length;
      },
      inputKeyUpHandler(event) {
        let query = event.target.value.trim();

        if (event.keyCode === 13) {
          this.submitKeyHandler();
        } else if (event.keyCode === 38 && this.suggestions && this.suggestions.length) {
          this.prevSuggestion();
        } else if (event.keyCode === 40 && this.suggestions && this.suggestions.length) {
          this.nextSuggestion();
        } else if (event.keycode !== 13 && query !== '' && query !== this.previousQuery) {
          this.previousQuery = query;
          this.search(query);
        } else if (event.keycode !== 13 && query === '') {
          this.previousQuery = '';
          this.showSuggestions = false;
          this.suggestions = [];
          this.activeListIndex = -1;
          this.activeSuggestionIndex = -1;
        }
      },
      search(query) {
        if (this.disableSuggestions) return;

        this.pendingRequest = searchSuggestions()
          .searchForQuery(encodeURIComponent(query), this.scope)
          .catch(error => { pigeon.publish('globalAppMessage:show', {error: error}) });

        this.pendingRequest
          .then(this.updateSuggestions.bind(this));
      },
      updateSuggestions(suggestions) {
        this.suggestions = suggestions;
        this.showSuggestions = !!this.suggestions.length;
      },
      chooseSuggestion(listIndex, suggestionIndex) {
        const item = this.suggestions[listIndex].suggestions[suggestionIndex];

        if (item.link) {
          window.location.href = item.link;
        } else {
          this.searchModel.search = item.value;
          this.$nextTick(this.submitForm.bind(this));
        }
      },
      submitForm() {
        if (this.searchModel.search) {
          this.$refs.form.submit();
        }
      },
      submitKeyHandler() {
        if (this.activeListIndex > -1 && this.activeSuggestionIndex > -1) {
          this.chooseSuggestion(this.activeListIndex, this.activeSuggestionIndex);
        } else {
          this.submitForm();
        }
      },
      hideSuggestions() {
        this.showSuggestions = false;
      },
      nextSuggestion() {
        this.activeListIndex = this.activeListIndex < 0 ? this.getNextListIndex() : this.activeListIndex;

        if (this.activeSuggestionIndex + 1 > this.suggestions[this.activeListIndex].suggestions.length - 1) {
          this.activeListIndex = this.getNextListIndex();
          this.activeSuggestionIndex = this.activeListIndex >= 0 ? 0 : -1;
        } else {
          this.activeSuggestionIndex += 1;
        }
      },
      getNextListIndex() {
        let currentIndex = this.activeListIndex;
        let nextIndex;

        for (let i = currentIndex; i < this.suggestions.length; i++) {
          if (this.suggestions[i + 1]) {
            if (this.suggestions[i + 1].suggestions && this.suggestions[i + 1].suggestions.length) {
              nextIndex = i + 1;
              break;
            }
          } else {
            nextIndex = -1;
            break
          }
        }

        return nextIndex;
      },
      prevSuggestion() {
        if (this.activeSuggestionIndex - 1 < 0) {
          this.activeListIndex = this.getPrevListIndex();
          this.activeSuggestionIndex = this.activeListIndex >= 0 ? this.suggestions[this.activeListIndex].suggestions.length -1 : -1;
        } else {
          this.activeSuggestionIndex -= 1;
        }
      },
      getPrevListIndex() {
        let currentIndex = this.activeListIndex;
        let prevIndex;

        for (let i = currentIndex; i >= -1; i--) {
          if (i - 1 < -1) {
            i = this.suggestions.length;
          }

          if (this.suggestions[i - 1]) {
            if (this.suggestions[i - 1].suggestions && this.suggestions[i - 1].suggestions.length) {
              prevIndex = i - 1;
              break;
            }
          } else {
            prevIndex = -1;
            break;
          }
        }

        return prevIndex;
      },
      buttonClickHandler(event) {
        event.preventDefault();
        this.submitForm();
      },
      buttonClearClickHandler(event) {
        this.suggestions = [];
        this.showSuggestions = false;
        this.searchModel.search = '';
        this.previousQuery = '';
        if (this.$refs.input)
          this.$refs.input.focusInput();
      }
    }
  };

  export default SearchAutosuggest;
</script>
