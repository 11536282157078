<template>
  <lazy-load
    v-if="enabled"
    class="lazy-load-image"
    :data-img-src="src"
  >
    <image-element
      :img-class="imgClass"
      :src="[src]"
      :alt="alt"
    />
  </lazy-load>
  <image-element
    v-else
    :img-class="imgClass"
    :src="[src]"
    :alt="alt"
  />
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import ImageElement from '../imageElement/imageElement.vue';

  @Options({
    name: 'lazy-load-image',
    components: {
      LazyLoad,
      ImageElement
    }
  })
  export default class LazyLoadImage extends Vue {
    @Prop({type: Boolean, default: true})
    enabled: boolean;

    @Prop()
    imgClass: string;

    @Prop()
    src: string;

    @Prop()
    alt: string;

    @Prop()
    title: string;
  }
</script>
