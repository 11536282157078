<template>
  <component
    :is="tag"
    :class="lazyClass"
  >
    <slot
      v-if="isActive"
    />
  </component>
</template>

<script lang="ts">
  import {Vue, Prop, Options} from 'vue-property-decorator';
  import {intersectionService} from '../../utilities/js/intersectionService/intersectionService';
  intersectionService.createObserver('lazyload', {rootMargin: '300px'});
  window.addEventListener('hashchange', () => {
    intersectionService.disable();

    setTimeout(() => {
      intersectionService.enable();
      intersectionService.activateAll('lazyload');
    }, 1000);
  });

  @Options({
    name: 'lazy-load'
  })
  export default class LazyLoad extends Vue {
    @Prop({default: 'DIV'})
    tag: string;
    @Prop()
    className: string;

    isActive = false;

    mounted() {
      intersectionService.observeElement('lazyload', this.$el, this.onIntersection);
    }

    onIntersection(element: Element) {
      this.isActive = true;
      // triggers mounted() on child components
    }

    get lazyClass() {
      return !this.isActive ? 'lazy-load' : this.className;
    }
  }
</script>
