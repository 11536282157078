import snakeToCamelCase from '../../utilities/js/snakeToCamelCase/snakeToCamelCase';

class JsComponent {
  constructor(mainElement, name) {
    this.mainElement = mainElement;
    this.name = name;
  }

  createClassNames() {
    this.classNames = {};

    for (let className of Array.from(arguments)) {
      let propName = snakeToCamelCase(className.replace(/^(_|-){2,}/, '').replace(/(_+|-{2,})/g, '-'));
      this.classNames[propName] = `${this.name}${className}`;
    }
  }

}

export default JsComponent;
