<template>
  <span
    class="image-element"
    :class="{
      'image-element--load-error': isError
    }"
  >
    <picture
      v-if="imgSrcMobile && imgSrcDesktop && imgSrc"
      class="image-element__pic"
      :class="pictureClass"
    >
      <template
        v-if="loadWebp"
      >
        <source
          :srcset="getWebpSrc(imgSrcMobile)"
          :media="`(max-width: ${breakpoint}px)`"
          type="image/webp"
        >
        <source
          :srcset="getWebpSrc(imgSrcDesktop)"
          :media="`(min-width: ${breakpoint}px)`"
          type="image/webp"
        >
      </template>
      <source
        :srcset="getWebpFallbackSrc(imgSrcMobile)"
        :media="`(max-width: ${breakpoint}px)`"
      >
      <source
        :srcset="getWebpFallbackSrc(imgSrcDesktop)"
        :media="`(min-width: ${breakpoint}px)`"
      >
      <img
        class="image-element__img"
        :class="imgClass"
        :src="imgSrc"
        :alt="alt"
        :width="width"
        :height="height"
        :loading="loading"
        @error="errorHandler"
      >
    </picture>
    <picture
      v-else-if="!imgSrcMobile && imgSrcDesktop && imgSrc"
      class="image-element__pic"
      :class="pictureClass"
    >
      <template
        v-if="loadWebp"
      >
        <source
          :srcset="getWebpSrc(imgSrcDesktop)"
          type="image/webp"
        >
      </template>
      <source
        :srcset="getWebpFallbackSrc(imgSrcDesktop)"
      >
      <img
        class="image-element__img"
        :class="imgClass"
        :src="imgSrc"
        :alt="alt"
        :width="width"
        :height="height"
        :loading="loading"
        @error="errorHandler"
      >
    </picture>
    <picture
      v-else-if="loadWebp"
    >
      <source
        :srcset="getWebpSrc(imgSrc)"
        type="image/webp"
      >
      <source
        :srcset="getWebpFallbackSrc(imgSrc)"
        media="(min-width: 1px)"
      >
      <img
        class="image-element__img"
        :class="imgClass"
        :src="imgSrc"
        :alt="alt"
        :width="width"
        :height="height"
        :loading="loading"
        @error="errorHandler"
      >
    </picture>
    <img
      v-else
      class="image-element__img"
      :class="imgClass"
      :src="imgSrc"
      :alt="alt"
      :width="width"
      :height="height"
      :loading="loading"
      @error="errorHandler"
    >
  </span>
</template>

<script lang="ts">
  import {Vue, Options, Prop, Watch} from 'vue-property-decorator';
  import jsGlobalConfig from '../../globals/jsGlobalConfig';
  import diContainer from '../../utilities/js/diContainer/diContainer';
  import type {TransformerPipeline} from '../../utilities/js/imageUrlTransformer/TransformerPipeline';

  @Options({
    name: 'image-element'
  })
  export default class ImageElement extends Vue {
    @Prop({required: true, type: Array})
    src: string[];

    @Prop({type: Array})
    srcMobile: string[];

    @Prop({type: String})
    alt: string;

    @Prop({type: Number, default: 767})
    breakpoint: number;

    @Prop({default: 200})
    width: number;

    @Prop({default: 100})
    height: number;

    @Prop()
    pictureClass: string;

    @Prop()
    imgClass: string;

    @Prop()
    fallbackUrl: string;

    @Prop({default: false})
    webp: boolean;

    @Prop({type: String, default: 'lazy'})
    loading: string;

    isError: boolean = false;
    imgSrc: string = ''
    imgSrcMobile: string = '';
    imgSrcDesktop: string = '';

    productImgUrlPattern = /\/{2}[^/]+\/[^/]+\/[\w,\s-]+\.(png|jpg)/g;
    imageUrlTransformer: TransformerPipeline = diContainer.get('imageUrlTransformer');

    created() {
      this.setImageUrls();
    }

    @Watch('src')
    @Watch('srcMobile')
    setImageUrls() {
      this.imgSrc = (this.src) ? this.imageUrlTransformer.execute(this.src[0]) : null;
      this.imgSrcMobile = (this.srcMobile) ? this.getFullSrcString(this.srcMobile) : null;
      this.imgSrcDesktop = ((this.src && this.src.length > 1) || (this.src && this.imgSrcMobile)) ? this.getFullSrcString(this.src) : null;
      if (!this.imgSrc) {
        this.errorHandler();
      }
    }

    getFullSrcString(srcArray: string[]) {
      if (!srcArray || srcArray.length < 1) {
        return null;
      }
      const src = [];
      for (let i = 0, l = srcArray.length; i < l; i += 1) {
        src.push(`${this.imageUrlTransformer.execute(srcArray[i])} ${i+1}x`);
      }
      return src.join(', ');
    }

    getWebpSrc(src: string) {
      if (this.isWebpUrl) {
        return src;
      }
      let result = src;
      const urlMatches = src.match(this.productImgUrlPattern);
      if (urlMatches.length) {
        urlMatches.forEach((url) => {
          const srcArray = url.split('/');
          if (srcArray[3].includes('_90')) {
            srcArray[3] = (srcArray[3].includes('retina')) ?
              srcArray[3].replace('_retina_90', '_transparent_retina_webp') :
              srcArray[3].replace('_90', '_transparent_webp');
          } else {
            srcArray[3] += '_webp';
          }
          result = result.replace(url, srcArray.join('/'));
        });
        result = result.replace(/(\.jpg|\.png)/g, '.webp');
      } else {
        // eslint-disable-next-line no-console
        console.warn('Image Source mismatch: ' + src);
      }
      return result;
    }

    getWebpFallbackSrc(src: string) {
      const fallbackFormat = this.imgSrc.match(/\.png$/) ? 'png' : 'jpg';
      return src
        .replace('/master/', `/bo_${fallbackFormat}/`)
        .replace('.webp', `.${fallbackFormat}`);
    }

    errorHandler() {
      const fallback = this.fallbackUrl || jsGlobalConfig().getFallbackImage();
      this.imgSrc = fallback;
      if (this.imgSrcMobile) {
        this.imgSrcMobile = fallback;
      }
      if (this.imgSrcDesktop) {
        this.imgSrcDesktop = fallback;
      }
      this.isError = true;
    }

    get loadWebp() {
      return this.isWebpUrl || this.imgSrc.match(/\.(png|jpg)$/) && this.webp;
    }

    get isWebpUrl() {
      return !!this.imgSrc.match(/\.webp$/gi);
    }
  }
</script>
