const bodyElement = document.body;

function bind(el, binding) {
  if (binding.arg && !el.bodyClickHandler) {
    if (typeof binding.value !== 'function') {
      // eslint-disable-next-line no-console
      console.error(`[v-click-outside:] provided expression ${binding.expression} is not a function, but has to be`);
    }

    el.bodyClickHandler = function(event) {
      if (!el.contains(event.target)) {
        binding.value(event);
      }
    }

    setTimeout(() => {
      bodyElement.addEventListener('click', el.bodyClickHandler);
    }, 0);
  } else if (!binding.arg && el.bodyClickHandler) {
    unbind(el);
  }
}

function unbind(el) {
  bodyElement.removeEventListener('click', el.bodyClickHandler);
  el.bodyClickHandler = null;
}

const outsideClick = {
  mounted: bind,
  updated: bind,
  unmounted: unbind
};

export default outsideClick;
