<template>
  <div class="product-box-wide__segment-center">
    <a
      class="product-box-wide__header product-box__segment-header"
      :href="productBoxData.url"
    >
      <div class="product-box-wide__header-text product-box__header-text">
        <span
          v-if="productBoxData.vintage"
          class="product-box-wide__vintage product-box__vintage"
        >
          {{ productBoxData.vintage }}
        </span>
        <span class="product-box-wide__name product-box__name">
          {{ productBoxData.description1 }}
        </span>
        <span
          class="product-box__short-description"
          v-if="productBoxData.description2"
        >
          {{ productBoxData.description2 }}
        </span>
        <span
          class="product-box-wide__short-description product-box__short-description"
          v-if="productBoxData.shortDescription"
        >
          {{ productBoxData.shortDescription }}
        </span>
      </div>
    </a>
    <div
      v-if="productBoxData.ratings > minimumRating || productBoxData.inBasket"
      class="product-box-wide__rating-container"
    >
      <span class="product-box-wide__bottle-amount" />
      <a
        class="product-box__status product-box__status--basket product-box-wide__status"
        v-if="productBoxData.inBasket"
        :href="basketUrl"
      >
        {{ $t('ads.ads_top_info.article_in_basket') }}
      </a>
      <a
        class="product-box__status product-box__status--basket"
        v-if="state === 'successorInBasket'"
        :href="basketUrl"
        v-t="'ads.ads_top_info.successor_in_basket'"
      />
    </div>
    <div
      class="product-box-wide__long-description"
      v-html="descriptionExcerpt"
    />
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';

  @Options({
    name: 'product-box-wide-segment-center',
  })
  export default class ProductBoxWideSegmentCenter extends Vue {
    @Prop()
    descriptionExcerpt: String;
    @Prop()
    minimumRating: String;
    @Prop()
    basketUrl: String;
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    state: string;
  }
</script>
