class AccountRecurringOrderItemSettings {
  buttonClass: {
    edit: string;
    skip: string;
    link: string;
    items: string;
    cancel: string;
    submit: string;
  }
}

const accountRecurringOrderItemSettings = new AccountRecurringOrderItemSettings();
accountRecurringOrderItemSettings.buttonClass = {
  edit: 'button--primary',
  skip: 'button--primary',
  link: 'button--link',
  items: 'button--secondary',
  cancel: 'button--secondary',
  submit: 'button--primary',
}

export {accountRecurringOrderItemSettings, AccountRecurringOrderItemSettings};
