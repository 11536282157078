<template>
  <component
    class="trusted-shop-badge"
    :is="noLazyLoad ? 'div' : 'lazy-load'"
  >
    <div id="trustbadge" />
    <template
      v-if="loadTrustedShop"
    >
      <script-loader
        :src="jsUrl"
      />
      <div
        class="trusted-shop-badge__more-link"
        v-if="linkUrl"
      >
        <a
          :href="linkUrl"
          target="_blank"
          v-html="linkText"
        />
      </div>
    </template>
  </component>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import ScriptLoader from '../scriptLoader/scriptLoader.vue';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';

  declare global {
    interface Window { _tsConfig: any; }
  }

  @Options({
    name: 'trusted-shop-badge',
    components: {
      LazyLoad,
      ScriptLoader
    }
  })
  export default class TrustedShopBadge extends Vue {
    @Prop({type: Object})
    readonly trustedData;
    @Prop()
    readonly jsUrl: string;
    @Prop()
    readonly linkText: string;
    @Prop()
    readonly linkUrl: string;
    @Prop({default: '70'})
    readonly height: string;
    @Prop({default: 'custom_reviews'})
    readonly variant: string;
    @Prop({default: true})
    readonly isLazyLoad: boolean;

    loadTrustedShop: boolean = false;

    mounted() {
      window._tsConfig = window._tsConfig || [];
      if (!this.trustedData) {
        window._tsConfig = {
          'yOffset': '0', /* offset from page bottom */
          'variant': this.variant, /* text, default, small, reviews, custom, custom_reviews */
          'customElementId': 'trustbadge', /* required for variants custom and custom_reviews */
          'trustcardDirection': 'topRight', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
          'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
          'customBadgeHeight': this.height, /* for custom variants: 40 - 90 (in pixels) */
          'disableResponsive': 'true', /* deactivate responsive behaviour */
          'disableTrustbadge': 'false', /* deactivate trustbadge */
          'trustCardTrigger': 'click', /* set to 'click' if you want the trustcard to be opened on click instead */
          'customCheckoutElementId': 'customCheckoutDiv'
        };
      } else {
        window._tsConfig = this.trustedData;
      }
      this.loadTrustedShop = true;
    }

    get noLazyLoad() {
      const isCheckoutConfirmationPage = document.querySelector('.checkout-confirmation-layout');
      return !!isCheckoutConfirmationPage || !this.isLazyLoad;
    }
  }
</script>
