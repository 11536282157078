<template>
  <li
    class="navigation__element"
    :class="{
      'navigation__element--is-touch': isTouch,
      'navigation__element--hover': hover,
      'navigation__element--visible': visible,
      'navigation__element--mouseout': mouseout
    }"
    @click="clickNaviElement"
    @mouseover="mouseoverElement"
    @mouseleave="mouseoutElement"
  >
    <slot />
  </li>
</template>

<script lang="ts">
  import {Vue, Options} from 'vue-property-decorator';

  @Options({
    name: 'navigation-element'
  })
  export default class NavigationElement extends Vue {

    visible: boolean = false;
    hover: boolean = false;
    mouseout: boolean = false;
    isTouch: boolean = false;

    mounted() {
      document.body.addEventListener('click', this.close);
      this.isTouch = 'ontouchstart' in window;
    }

    destroyed() {
      document.body.removeEventListener('click', this.close);
    }

    clickNaviElement(event) {
      if (this.isTouch && !this.visible) {
        event.preventDefault();
        this.visible = true;
      } else {
        this.visible = false;
      }
    }

    close(event) {
      const clickElement = event.target.closest('.navigation__element');

      if (this.isTouch && this.visible && clickElement && clickElement.id !== this.$el.id) {
        this.visible = false;
      }
    }

    mouseoverElement() {
      if (!this.isTouch) {
        this.hover = true;
        this.mouseout = false;
      }
    }

    mouseoutElement() {
      if (!this.isTouch) {
        this.hover = false;
        this.visible = false;
        this.mouseout = true;
      }
    }
  }
</script>
