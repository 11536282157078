<template>
  <div
    class="read-more"
    :class="{
      'read-more--open': open,
      'read-more--closed': !open
    }"
  >
    <div
      class="read-more__container"
      :style="style"
    >
      <div
        class="read-more__slot"
        ref="slotElement"
      >
        <slot />
      </div>
    </div>

    <div
      v-if="!disabled"
      class="read-more__btn"
      @click="toggle()"
      ref="lessButton"
    >
      {{ open ? $t(textLess) : $t(textMore) }}
    </div>
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import * as scrollToElement from 'scroll-to-element';

  @Options({
    name: 'read-more'
  })
  export default class ReadMore extends Vue {
    @Prop({default: 100})
    maxHeight: number;

    @Prop()
    textMore: string;

    @Prop()
    textLess: string;

    @Prop()
    scrollLessElement: string;

    open: boolean = false;
    elementHeight: number = 0;

    get disabled(): boolean {
      // if empty or content only two lines heighter, then don't show button
      return this.maxHeight >= this.elementHeight || (this.elementHeight - this.maxHeight) < 80;
    }

    get style() {
      if (this.disabled) {
        return null;
      }

      return {
        maxHeight: (this.open ? 'none' : `${this.maxHeight}px`)
      }
    }

    get text() {
      return this.$t(this.textLess);
    }

    mounted() {
      this.setHeight();

      window.addEventListener('resize', () => {
        this.setHeight();
      });
    }

    updated() {
      this.setHeight();
    }

    setHeight() {
      const slotElement: any = this.$refs.slotElement;
      if (slotElement) {
        this.elementHeight = slotElement.getBoundingClientRect().height + 30;
      }
    }

    toggle() {
      if (this.disabled) {
        return;
      }

      this.open = !this.open;

      if (!this.open && this.scrollLessElement) {
        const item = document.querySelector(this.scrollLessElement);
        const stickyNavigation = document.querySelector('.product-navigation__container');
        let offsetHeight = -10;
        if (stickyNavigation && stickyNavigation.classList.contains('product-navigation__container--sticky')) {
          offsetHeight -= stickyNavigation.getBoundingClientRect().height;
        }

        window.setTimeout(() => {
          const lessButton: any = this.$refs.lessButton;
          if (this.isElementInViewport(lessButton)) {
            scrollToElement(item, {duration: 400, offset: offsetHeight});
          }
        }, 50);
      }
    }

    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return rect && Math.round(rect.bottom) < 0;
    }

  }
</script>
