
type StringTransformer = (input: string) => string;

class TransformerPipeline {
  protected transformers: StringTransformer[] = [];

  add(transformer: StringTransformer) {
    this.transformers.push(transformer);
  }

  execute(data: string) {
    let transformedData = data;
    for (let key in this.transformers) {
      transformedData = this.transformers[key](transformedData);
    }

    return transformedData;
  }
}


export {
  TransformerPipeline,
  StringTransformer
}
