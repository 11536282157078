<template>
  <a
    v-if="productBoxData.valid"
    :href="productStarHref"
    aria-label="product review"
    class="product-box__segment-review"
  >
    <div class="ratings">
      <div class="ratings__wrapper">
        <template>
          <span
            class="ratings__label ratings__star radio-input"
            :class="{
              [`ratings__label--index-${5 - index}`]: true,
              'ratings__star--empty': true
            }"
            v-for="(rating, index) in ratings"
            :key="`${5 - index}_${rating.value}`"
            :for="rating.value"
            @mouseover="changeRating(rating.value)"
            @mouseleave="changeRating(initialRating)"
          />
        </template>
      </div>
    </div>
    <div
      class="product-box__segment-review__headline"
      v-html="$t('productbox.product.review.headline')"
    />
  </a>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../types/ProductBoxData';
  @Options({
    name: 'product-box-segment-review'
  })
  export default class ProductBoxSegmentReview extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop({default: 5})
    initialRating!: number;
    @Prop()
    productLink: string;

    ratings = [{value: 5}, {value: 4}, {value: 3}, {value: 2}, {value: 1}];
    model = {
      rating: this.initialRating,
    };

    get productStarHref() {
      return this.productLink.replace('#reviewForm', `&star=${this.model.rating}#reviewForm`)
    }

    changeRating(value) {
      this.model.rating = value;
    }
  }
</script>
