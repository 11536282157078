<template>
  <div
    class="products-list"
    :class="className"
  >
    <div
      class="products-list__element products-list__element--type_product"
      v-for="(product, index) in listItems"
      :key="product.itemId"
    >
      <component
        :is="productBoxComponent"
        class="products-list__product-box"
        :product-box-data="product"
        :hide-counter-button="false"
        :state="getProductState(product)"
        :tracking-additional="{
          position: index,
          list: trackingList
        }"
        @addToBasket="addToBasketHandler"
        @quantityChange="quantityChangeHandler"
        @addToWishlist="addToWishlistHandler"
        @removeFromWishlist="removeFromWishlistHandler"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {Options, Prop} from 'vue-property-decorator';
  import ProductBox from '../productBox/productBox.vue';
  import ProductBoxWide from '../productBoxWide/productBoxWide.vue';
  import {productBoxContainerMixin, ProductBoxContainerClass} from '../../utilities/vueMixins/productBoxContainerMixin/productBoxContainerMixin';
  import breakpoints from '../../variables/js/breakpoints';

  @Options({
    name: 'product-box-list',
    components: {
      ProductBox,
      ProductBoxWide
    },
    mixins: [productBoxContainerMixin]
  })
  export default class ProductBoxList extends ProductBoxContainerClass {
    @Prop({required: false, default: 'list'})
    listType: 'list'|'box';

    isMobile: boolean = null;

    mounted() {
      window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
      this.breakpointChangeHandler();
    }

    get className() {
      return 'products-list--type_' + this.listType;
    }

    get productBoxComponent(): string {
      return this.isMobile || this.listType === 'box' ? 'ProductBox' : 'ProductBoxWide';
    }

    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.tablet;
    }
  }
</script>
