class VideoPlayerSettings {
  byline: boolean;
  portrait: boolean;
  title: boolean;
  speed: boolean;
  color?: string;
}
const videoPlayerSettings = new VideoPlayerSettings();
videoPlayerSettings.byline = false;
videoPlayerSettings.portrait = false;
videoPlayerSettings.title = false;
videoPlayerSettings.speed = false;

export {videoPlayerSettings, VideoPlayerSettings};
