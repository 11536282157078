class StopEnter {
  constructor(mainElement) {
    this.mainElement = mainElement;
    this.mainElement.onkeypress = (event) => this.stopEnter(event);
  }

  stopEnter(event) {
    if (event && event.target && (event.target.type === 'text' || event.target.type === 'number')) {
      return event.keyCode !== 13;
    }
    return true;
  }
}

export default StopEnter;
