import JsComponent from '../jsComponent/jsComponent.js';
import {documentHelper} from '../../utilities/js/documentHelper/documentHelper';

class Sidebar extends JsComponent {
  constructor(mainElement, name = 'sidebar') {
    super(mainElement, name);
    const wrapper = document.querySelector('.sidebar__wrapper');
    const stickySidebar = document.querySelector('.sidebar');
    const stickySidebarCheckoutArea = document.querySelector('.sidebar__checkout-area');
    const wrapperLeft = document.querySelector('.sidebar__wrapper-left');
    const wrapperRight = document.querySelector('.sidebar__wrapper-right');

    if (wrapper && stickySidebar) {
      documentHelper.ready(() => {
        this.stickify(wrapper, stickySidebar, wrapperRight, wrapperLeft, stickySidebarCheckoutArea);
      });
      document.addEventListener('scroll', (() => { this.stickify(wrapper, stickySidebar, wrapperRight, wrapperLeft, stickySidebarCheckoutArea); }).bind(this));
      document.addEventListener('resize', (() => { this.stickify(wrapper, stickySidebar, wrapperRight, wrapperLeft, stickySidebarCheckoutArea); }).bind(this));
    }
  }

  stickify(wrapper, stickySidebar, wrapperRight, wrapperLeft, stickySidebarCheckoutArea) {
    stickySidebar.classList.remove('sidebar--fixed');
    stickySidebar.classList.remove('sidebar--bottom');
    stickySidebar.classList.remove('sidebar--absolute');
    stickySidebar.classList.remove('sidebar--relative');

    const wrapperRect = wrapper.getBoundingClientRect();
    const wrapperRightRect = wrapperRight.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const stickySidebarHeight = stickySidebarCheckoutArea ? stickySidebarCheckoutArea.offsetHeight : 0;

    // set sidebar relative, not sticky
    if (!wrapperLeft || !wrapperRight || (wrapperLeft.offsetHeight < stickySidebar.offsetHeight && wrapperRightRect.top < windowHeight - stickySidebarHeight)) {
      stickySidebar.classList.add('sidebar--relative');
      return;
    }

    // sticky sidebar
    stickySidebar.classList.add('sidebar--absolute');
    if (wrapperRect.top !== wrapperRightRect.top) {
      // mobile
      if (wrapperRightRect.top < windowHeight - stickySidebarHeight) {
        stickySidebar.classList.remove('sidebar--absolute');
        stickySidebar.classList.add('sidebar--relative');
      } else {
        stickySidebar.classList.add('sidebar--fixed');
      }
    } else {
      // desktop
      if (wrapperRect.bottom < stickySidebar.offsetHeight) {
        stickySidebar.classList.add('sidebar--bottom');
      } else if (wrapperRect.top <= 0) {
        stickySidebar.classList.add('sidebar--fixed');
      }
    }
  }
}

export default Sidebar;
