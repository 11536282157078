<template>
  <div
    class="product-box-wide"
    :class="{
      'product-box--in-basket': productBoxData.inBasket,
      'product-box--error': state === 'error',
      'product-box-wide--personalization': productBoxData.personalPreferenceMessageKey,
      [`product-box--type-${productBoxData.productBoxType}`]: true,
      'product-box--highlight': productBoxData.highlightArticle,
    }"
    :data-id="productBoxData.id"
    :data-item-id="productBoxData.itemId"
    :data-artnr="productBoxData.trackingData.artNr"
    :data-wban="productBoxData.trackingData.wban"
  >
    <div
      v-if="productBoxData.highlightArticle"
      class="product-box-wide__highlight-text"
      v-html="$t(`highlightArticle.title.${productBoxData.highlightArticle}`)"
    />
    <div
      v-if="settingsWide.personalisationTop && productBoxData.personalPreferenceMessageKey"
      class="product-box-wide__personalization-banner-wrapper"
    >
      <div
        class="product-box-wide__personalization-banner personalization-banner"
        v-html="$t(productBoxData.personalPreferenceMessageKey)"
      />
    </div>

    <product-box-wide-segment-image
      :product-box-data="productBoxData"
      :settings="settingsWide"
      :img-src="imgSrc"
      :is-box-type-vertical="isBoxTypeVertical"
      :is-bottle="isBottle"
    />

    <product-box-wide-segment-center
      :description-excerpt="descriptionExcerpt"
      :product-box-data="productBoxData"
      :show-availability="showAvailability"
      :minimum-rating="minimumRating"
      :basket-url="basketUrl"
      :state="state"
      :settings="settingsWide"
    />
    <div
      class="product-box-wide__segment-conversion"
      v-if="productBoxData.zeroPriceItem"
    >
      <div class="product-box-wide__price-wrapper product-box-wide__price-wrapper--no-price">
        <div class="product-box__buttons">
          <a
            class="product-box__status product-box__status--unavailable"
            :href="productBoxData.url"
          >
            {{ $t('availability.no-price') }}
          </a>
        </div>
      </div>
    </div>
    <product-box-wide-segment-conversion
      v-else
      :product-box-data="productBoxData"
      :settings="settingsWide"
      :state="state"
      :hide-counter-button="hideCounterButton"
      :food-specification-link="foodSpecificationLink"
      :hide-price="isPriceHidden"
      :show-availability="showAvailability"
      :minimum-rating="minimumRating"
      :add-successor-active="addSuccessorActive"
      :basket-url="basketUrl"
      :product-link="productLink"
      @wishlistClicked="toggleWishlist"
      @addToBasket="addToBasket"
      @quantityChange="changeQuantity"
      @onRequestClicked="openOnRequestLightBox"
    />
  </div>
</template>

<script lang="ts">
  import {ref, computed} from 'vue';
  import {excerpt} from '../../utilities/js/excerpt/excerpt';
  import ProductBox from '../productBox/productBox.vue';
  import breakpoints from '../../variables/js/breakpoints';
  import {ProductBoxWideSettings} from './productBoxWideSettings';
  import diContainer from '../../utilities/js/diContainer/diContainer';

  const EXCERPT_LONG = 40;
  const EXCERPT_SHORT = 30;

  export default {
    name: 'ProductBoxWide',
    mixins: [ProductBox],

    setup(props) {
      const excerptLength = ref(EXCERPT_LONG);
      function breakpointChangeHandler() {
        excerptLength.value = window.innerWidth > breakpoints.laptop ?
          EXCERPT_LONG : EXCERPT_SHORT;
      }
      window.addEventListener('breakpointChange', breakpointChangeHandler);
      breakpointChangeHandler();

      const settingsWide: ProductBoxWideSettings = diContainer.get('productBoxWideSettings');

      const imgSrc = computed(() => {
        return props.productBoxData.images.length > 3
          ? [props.productBoxData.images[1].src, props.productBoxData.images[3].src]
          : [props.productBoxData.images[1].src];
      })

      return {
        excerptLength,
        settingsWide,
        imgSrc
      }
    },

    computed: {
      descriptionExcerpt() {
        // needs to be in computed options because of translations
        if (this.productBoxData.longDescription) {
          if (this.settingsWide.readMoreLink) {
            const link = '... <a class="read-more__btn" href="' + this.productBoxData.url + this.$t('productboxWide.read_more.anker') + '">' + this.$t('productboxWide.read_more.text') + '</a>';
            return excerpt(this.productBoxData.longDescription, this.excerptLength, link);
          } else {
            return excerpt(this.productBoxData.longDescription, this.excerptLength);
          }
        }
        return '';
      }
    }
  }
</script>
