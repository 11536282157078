import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lazy_load = _resolveComponent("lazy-load")!

  return (_openBlock(), _createBlock(_component_lazy_load, {
    tag: "ul",
    class: "tags"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tag.name,
          class: "tags__list"
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["tags__tag | tag", _ctx.getTagClassName(tag)]),
            onClick: ($event: any) => (_ctx.handleClick(tag))
          }, _toDisplayString(tag.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_1)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}