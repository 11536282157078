class ProductBoxSettings {
  showArea: boolean;
  showAvailabilityStatus: string[];
  flags: {
    src: {
      boxSetImage: string,
      awardInSetImage: string,
    },
    whitelist: string[],
    whitelistTag: string[],
    whitelistInSet: string[]
  }
  rating: {
    type: string
  }
  price: {
    showTax: boolean
  }
  slider: {
    mobile: {
      offset: number,
      centeredSlides: boolean
    }
    tablet: {
      offset: number,
      centeredSlides: boolean
    }
    desktop: {
      offset: number,
      centeredSlides: boolean
    }
  }
  referredByHash: string
}
const productBoxSettings = new ProductBoxSettings();

productBoxSettings.showArea = false;
productBoxSettings.showAvailabilityStatus = ['LATER_AVAILABLE'];
productBoxSettings.flags = {
  src: {
    boxSetImage: '/assets/bps/frontend-core/staticImages/wood-box.png',
    awardInSetImage: '/assets/bps/frontend-core/staticImages/awardInSet.svg'
  },
  whitelist: [],
  whitelistTag: [],
  whitelistInSet: []
};
productBoxSettings.rating = {
  type: 'disabled'
};
productBoxSettings.price = {
  showTax: false
};
productBoxSettings.referredByHash = '#referredBy=';
productBoxSettings.slider = {
  mobile: {
    offset: 0.15,
    centeredSlides: true
  },
  tablet: {
    offset: 0.15,
    centeredSlides: false
  },
  desktop: {
    offset: 0,
    centeredSlides: false
  }
};

export {productBoxSettings, ProductBoxSettings};
