<template>
  <div
    class="search-autosuggest__suggestions-list"
    :class="{
      'search-autosuggest__suggestions-list--category': suggestionItem.type === 'categorySuggestions',
      'search-autosuggest__suggestions-list--top-search': suggestionItem.type === 'topSearchSuggestions',
      'search-autosuggest__suggestions-list--product': suggestionItem.type === 'productSuggestions'
    }"
  >
    <span
      class="search-autosuggest__suggestions-name"
      v-if="suggestionItem.suggestions[0].title"
    >
      {{ suggestionItem.suggestions[0].title }}
    </span>
    <a
      class="search-autosuggest__suggestion"
      v-for="(suggestion, suggestionIndex) in suggestionItem.suggestions"
      :class="{
        'search-autosuggest__suggestion--active': activeListIndex === listIndex && activeSuggestionIndex === suggestionIndex
      }"
      :href="suggestion.link ? suggestion.link : '#'"
      @click.prevent="chooseSuggestion(listIndex, suggestionIndex)"
      :key="suggestionIndex"
    >
      <img
        v-if="suggestion.imageVersions"
        :src="suggestion.imageVersions.thumbnail"
        :alt="suggestion.value"
      >
      <span>{{ suggestion.value }}</span>
    </a>
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';

  @Options({
    name: 'suggestions-list-item'
  })
  export default class SuggestionsListItem extends Vue {
    @Prop()
    suggestion: any;
    @Prop()
    suggestionItem: any;
    @Prop()
    listIndex: number;
    @Prop()
    activeListIndex: number;
    @Prop()
    activeSuggestionIndex: number;
    @Prop()
    chooseSuggestion: any;
  }
</script>
