import JsComponent from '../jsComponent/jsComponent.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

class PageOverlay extends JsComponent {
  constructor(mainElement, name = 'page-overlay') {
    super(mainElement, name);
    this.createClassNames('--visible');
    this.bindEvents();
    this.scrollY = 0;
  }

  bindEvents() {
    pigeon.subscribe('pageoverlay:show', this.show.bind(this));
    pigeon.subscribe('pageoverlay:hide', this.hide.bind(this));
    pigeon.subscribe('pageoverlay:lock', this.lock.bind(this));
    pigeon.subscribe('pageoverlay:unlock', this.unlock.bind(this));

    this.mainElement.addEventListener('click', (event) => {
      if (event.target === this.mainElement) {
        pigeon.publish('pageoverlay:click');
      }
    });
  }

  show(additionalClasses) {
    if (additionalClasses !== 'page-overlay--transparent') {
      this.scrollY = window.scrollY;
      const body = document.body;
      body.style.height = '100%';
      body.style.overflowY = 'hidden';
    }
    this.mainElement.classList.add(this.classNames.visible);
    if (additionalClasses) {
      this.mainElement.classList.add(additionalClasses);
    }
  }

  hide(additionalClasses) {
    if (additionalClasses !== 'page-overlay--transparent') {
      window.scrollTo(0, this.scrollY);
      const body = document.body;
      body.style.height = '';
      body.style.overflowY = '';
    }
    this.mainElement.classList.remove(this.classNames.visible);
    if (additionalClasses) {
      this.mainElement.classList.remove(additionalClasses);
    }
  }

  lock(additionalClasses) {
    document.querySelector('html').classList.add('no-scroll');
    document.querySelector('body').classList.add('no-scroll');
    if (additionalClasses) {
      document.querySelector('html').classList.add(additionalClasses);
      document.querySelector('body').classList.add(additionalClasses);
    }
  }

  unlock(additionalClasses) {
    document.querySelector('html').classList.remove('no-scroll');
    document.querySelector('body').classList.remove('no-scroll');
    if (additionalClasses) {
      document.querySelector('html').classList.remove(additionalClasses);
      document.querySelector('body').classList.remove(additionalClasses);
    }
  }
}

export default PageOverlay;
