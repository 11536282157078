class ProductBoxWideSettings {
  flags: {
    whitelistImage: string[]
    whitelistTag: string[]
    whitelistConversion: string[]
  };
  rating: {
    type: string
  }
  readMoreLink: boolean
  personalisationTop: boolean
}

const productBoxWideSettings = new ProductBoxWideSettings();

productBoxWideSettings.flags = {
  whitelistImage: ['free', 'new', 'stoerer', 'award', 'nachhaltigkeit', 'bio', 'vegan', 'boxSetUrl'],
  whitelistTag: [],
  whitelistConversion: ['savings'],
};

productBoxWideSettings.rating = {
  type: 'disabled'
};

productBoxWideSettings.readMoreLink = true;
productBoxWideSettings.personalisationTop = false;

export {productBoxWideSettings, ProductBoxWideSettings};
