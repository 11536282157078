function initIShopEvents() {
  const iShopEventsElements = Array.from(document.querySelectorAll('._ishopevents'));

  iShopEventsElements.map(element => {
    let ishopevents = element.dataset.ishopevents;

    if (ishopevents) {
      ishopevents = JSON.parse(ishopevents);
      window._ishopevents.push(ishopevents);
    }
  });
}

export default initIShopEvents;
