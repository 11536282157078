interface Lightbox {
  open: () => void;
}

const lightboxQueue: Lightbox[] = [];

function addToQueue(lb: Lightbox) {
  lightboxQueue.push(lb);
}

function openNext() {
  const nextLb: Lightbox = lightboxQueue.shift();
  nextLb.open();
}

function onLightboxClose() {
  if (lightboxQueue.length > 0) {
    openNext();
  }
}

function pageHasActiveLightbox() {
  return document.querySelector('.lightbox__container') !== null
}

export {
  addToQueue,
  openNext,
  pageHasActiveLightbox,
  onLightboxClose,
}
