class DiContainer {
  contents = {};

  add(comp: {[key: string]: any}) {
    this.contents = {...this.contents, ...comp};
  }

  get(key: string): any {
    if (key in this.contents) {
      return this.contents[key];
    }
    throw new Error(`DiContainer: Content ${key} not found`);
  }

  async getAsync(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(key)()
        .then((module) => {
          if ('default' in module) {
            resolve(module.default);
          } else {
            resolve(module);
          }
        })
        .catch(() => reject());
    })
  }
}

// Decorator
function Injectable(key: string) {
  return function(value) {
    const container = {[key]: value}
    diContainer.add(container);
  }
}

const diContainer = new DiContainer();
export default diContainer;
export {Injectable}
