import httpRequest from '../../utilities/js/httpRequest/httpRequest';
import encodeFormData from '../../utilities/js/encodeFormData/encodeFormData';

class WeglotService {
  constructor() {}

  setLanguageChanged(newLang) {
    const url = `/selectlanguage/?language=${newLang}`;
    const data = {};
    const csrf = window.__globalConfig.csrfToken;
    data[csrf.name] = csrf.value;
    return httpRequest
      .post(url, encodeFormData(data))
      .catch((error) => {
        window.logJSError('Error Weglot setLanguageChanged', null, null, null, error);
      });
  }
}

const weglotService = new WeglotService();
export default weglotService;
