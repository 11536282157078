class FormSettings {
  checkboxIsSwitch: boolean;
  remainSignedIn: boolean;
}
const formSettings = new FormSettings();

formSettings.checkboxIsSwitch = false;
formSettings.remainSignedIn = false;

export {formSettings, FormSettings};
