import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import VMask from '@ssibrahimbas/v-mask';
import './globals/global';
import iShopEvents from './utilities/js/ishopEvents/ishopEvents.js';
import tracking from './utilities/js/tracking/Tracking.ts';
import breakpointChangeEvent from './utilities/js/breakpointChangeEvent/breakpointChangeEvent.js';
import httpRequest from './utilities/js/httpRequest/httpRequest';
import pigeon from './utilities/js/pigeon/pigeon';
import outsideClickDirective
  from './utilities/directives/outsideClickDirective/outsideClickDirective';


class App {
  constructor(JsComponents, VueComponents, VueGlobalComponents, name = 'novosales') {
    this.name = name;
    const vueRootElementId = `${this.name}-app`;

    this.rootElement = document.getElementById(vueRootElementId);
    this.vueInstance = null;
    this.jsComponents = {};
    this.vueComponents = VueComponents;

    this.init(JsComponents, VueComponents, VueGlobalComponents);
  }

  init(JsComponents, VueComponents, VueGlobalComponents) {
    this.createVueInstance();
    this.registerVueDirectives();
    this.registerVueComponents(VueGlobalComponents);
    this.registerVueComponents(VueComponents);
    this.ignoreVueComponents();
    this.vueInstance.mount(this.rootElement);

    this.initAllJsComponents(JsComponents);
    this.initGlobalUtilities();
  }

  initAllJsComponents(JsComponents) {
    if (JsComponents) {
      for (const component of Array.from(JsComponents)) {
        this.initJsComponent(component);
      }
    }
  }

  initJsComponent(component) {
    const componentNodeElements = document.querySelectorAll(`.${component.name}`);

    for (const componentNodeElement of componentNodeElements) {
      if (!(component.name in this.jsComponents)) {
        this.jsComponents[component.name] = [];
      }

      if (component.style) {
        component.style();
      }

      if (!Object.prototype.hasOwnProperty.call(component, 'constructor')) {
        continue;
      }
      else if (!this.isClass(component.constructor)) {
        // dynamic component import
        component.constructor().then((module) => {
          this.jsComponents[component.name].push(new module.default(componentNodeElement));
        });
      }
      else {
        this.jsComponents[component.name].push(new component.constructor(componentNodeElement));
      }
    }
  }

  registerVueDirectives() {
    this.vueInstance.use(VMask);
    this.vueInstance.directive('outside-click', outsideClickDirective);
  }

  registerUsedVueComponents(VueComponents) {
    if (VueComponents) {
      const componentsToRegister = VueComponents.filter(vueComponent =>
        !!document.querySelector(`${vueComponent.name}, [is="${vueComponent.name}"], [uses="${vueComponent.name}"]`)
      );
      this.registerVueComponents(componentsToRegister);
    }
  }

  registerAjaxVueComponents(html) {
    if (this.vueComponents) {
      const componentsToRegister = this.vueComponents.filter(vueComponent =>
        html.indexOf(vueComponent.name) !== -1
      );
      this.registerVueComponents(componentsToRegister);
    }
  }

  ignoreVueComponents() {
    this.vueInstance.config.ignoredElements = ['klarna-placement'];
  }

  registerVueComponents(components) {
    for (let i = 0; i < components.length; i++) {
      //console.log(components[i].name, components[i].constructor)
      this.vueInstance.component(components[i].name, components[i].constructor);
    }
  }

  createVueInstance() {
    const locale = this.rootElement.dataset.language;

    const i18n = createI18n({
      locale: locale,
      fallbackLocale: 'de',
      messages: {},
      warnHtmlInMessage: 'off',
      missing: (locale, key, vm) => {
        return '';
      }
    });

    // Circumvent bug in vue-i18n where $te returns false for existing keys
    // https://github.com/kazupon/vue-i18n/issues/1521
    i18n.global.te = (key, locale) => {
      const messages = i18n.global.messages[i18n.global.locale]
      return Object.hasOwn(messages, key);
    }

    this.vueInstance = createApp({
      beforeCreate() {
        const vm = this;
        httpRequest(window.__globalConfig.apiUrls.messages)
          .then((response) => {
            vm.$i18n.setLocaleMessage(locale, response.data);
            pigeon.publish('app:translationsComplete');
        });
      },
      data: () => ({
        getGlobalConfig: () => window.__globalConfig,
        getCheckoutConfig: () => window.__checkoutConfig,
        getPigeon: () => pigeon
      }),
    });

    this.vueInstance.config.compilerOptions.whitespace = 'preserve';

    this.vueInstance.config.errorHandler = (err, vm, info) => {
      if (window.onerror) {
        window.onerror(err.toString(), null, null, null, err);
      }
      throw (err);
    };

    this.vueInstance.config.warnHandler = (msg, vm, trace) => {
      const err = {
        name : '',
        msg : msg,
        stack : trace
      };
      if (window.onerror) {
        const matches = msg.match(/Reason: (.+): (.+)/);
        if (matches) {
          err.name = matches[1] ? matches[1] : '';
          err.msg = matches[2] ? matches[2] : msg;
        }
        window.onerror(err.msg, null, null, null, err);
      }
      console.warn(msg, vm, trace); // eslint-disable-line
    };

    this.vueInstance.use(i18n);
  }

  initGlobalUtilities() {
    iShopEvents();
    breakpointChangeEvent();

    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        tracking.init();
        if (window.location.hash) {
          window.location.href = window.location.hash;
        }
      }
    });
  }

  isClass(func) {
    return typeof func === 'function'
      && /^class(\s|{)/.test(Function.prototype.toString.call(func));
  }
}

export default App;
