import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_element = _resolveComponent("image-element")!
  const _component_lazy_load = _resolveComponent("lazy-load")!

  return (_ctx.enabled)
    ? (_openBlock(), _createBlock(_component_lazy_load, {
        key: 0,
        class: "lazy-load-image",
        "data-img-src": _ctx.src
      }, {
        default: _withCtx(() => [
          _createVNode(_component_image_element, {
            "img-class": _ctx.imgClass,
            src: [_ctx.src],
            alt: _ctx.alt
          }, null, 8 /* PROPS */, ["img-class", "src", "alt"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data-img-src"]))
    : (_openBlock(), _createBlock(_component_image_element, {
        key: 1,
        "img-class": _ctx.imgClass,
        src: [_ctx.src],
        alt: _ctx.alt
      }, null, 8 /* PROPS */, ["img-class", "src", "alt"]))
}