const LIST_MARKER_CATEGORY = '#category#';

function getTrackingListValue(data) {
  let result = '';
  const list = data.list || data.itemListName;
  if (list) {
    result = list.replace(LIST_MARKER_CATEGORY, location.pathname);
  } else {
    result = data.category || data.mainCategory || data.item_category;
  }

  return result;
}

export {getTrackingListValue, LIST_MARKER_CATEGORY};
