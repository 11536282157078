import {defineComponent, PropType} from 'vue';
import {Vue} from 'vue-property-decorator';
import type ProductBoxData from '../../../types/ProductBoxData';
import StateManager from '../../js/stateManager/StateManager';
import productService from '../../../services/product/ProductService';

// This is the real code used as mixin in components
const productBoxContainerMixin= defineComponent({
  props: {
    products: {
      type: Array as PropType<ProductBoxData[]>,
      required: true
    },
    hideCartButton: {
      type: Boolean,
      default: false
    },
    trackingList: {
      type: String,
      default: 'product-container'
    }
  },

  data() {
    return {
      listItems: [] as ProductBoxData[],
      productStates: new StateManager(),
    }
  },

  created() {
    this.createItems();
  },

  watch: {
    'products': 'createItems'
  },

  methods: {
    createItems() {
      this.listItems = [...this.products];
    },

    getProductState(product): string {
      return this.productStates.get(product.itemId);
    },

    addToBasketHandler(product: ProductBoxData, quantity: number) {
      this.productStates.set(product.itemId, StateManager.STATE_LOAD_BASKET);
      return productService.addToBasket(product, quantity, this.trackingList)
        .then(response => {
          for (let ndx = 0; ndx < this.listItems.length; ndx++) {
            if (product.soldoutRelation && this.listItems[ndx].itemId === product.soldoutRelation.itemId) {
              this.listItems[ndx].successorInBasket = true;
            }
            if (this.listItems[ndx].itemId === response.data.productDetails.product.itemId) {
              this.listItems[ndx].inBasket = true;
            }
          }
          this.listItems = [...this.listItems];
          this.productStates.remove(product.itemId);
        })
        .catch(() => {
          this.productStates.set(product.itemId, StateManager.STATE_ERROR);
        });
    },

    quantityChangeHandler(product: ProductBoxData, quantity: number) {
      return productService.changeQuantity(product.id, product.itemId, quantity)
        .then(response => {
          for (let ndx = 0; ndx < this.listItems.length; ndx++) {
            if (this.listItems[ndx].itemId === response.data.itemId) {
              this.listItems[ndx] = response.data;
              break;
            }
          }
          this.listItems = [...this.listItems];
        })
    },

    addToWishlistHandler(product: ProductBoxData) {
      this.productStates.set(product.itemId, StateManager.STATE_LOAD_WISHLIST);
      productService.addToWishlist(product.id, product.itemId, this.trackingList)
        .then(response => {
          for (let ndx = 0; ndx < this.listItems.length; ndx++) {
            if (this.listItems[ndx].itemId === product.itemId) {
              this.listItems[ndx].inWishlist = true;
              break;
            }
          }
          this.listItems = [...this.listItems];
          this.productStates.remove(product.itemId);
        })
        .catch(() => {
          this.productStates.set(product.itemId, StateManager.STATE_ERROR);
        });
    },

    removeFromWishlistHandler(product: ProductBoxData) {
      this.productStates.set(product.itemId, StateManager.STATE_LOAD_WISHLIST);
      productService.removeFromWishlist(product.id, product.itemId, this.trackingList)
        .then(response => {
          for (let ndx = 0; ndx < this.listItems.length; ndx++) {
            if (this.listItems[ndx].itemId === product.itemId) {
              this.listItems[ndx].inWishlist = false;
              break;
            }
          }
          this.listItems = [...this.listItems];
          this.productStates.remove(product.itemId);
        })
        .catch(() => {
          this.productStates.set(product.itemId, StateManager.STATE_ERROR);
        });
    },
  }
});

// Dummy class to be used for extending class based components
class ProductBoxContainerClass extends Vue {
  products!: ProductBoxData[];
  hideCartButton!: boolean;
  trackingList!: string;
  listItems!: ProductBoxData[];
  addToBasketHandler!: (product: ProductBoxData, quantity: number) => Promise<void>;
  quantityChangeHandler!: (product: ProductBoxData, quantity: number) => Promise<void>;
  addToWishlistHandler!: (product: ProductBoxData) => void;
  removeFromWishlistHandler!: (product: ProductBoxData) => void;
  getProductState!: (product: ProductBoxData) => string;
}


export {
  ProductBoxContainerClass,
  productBoxContainerMixin
}
