<template>
  <component
    :is="productClickable ? 'a' : 'span'"
    :href="productClickable ? product.url || product.productUrl : null"
    class="product-name"
  >
    {{ product.name }}
  </component>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import BasketItem from '../../types/BasketItem';
  import ProductBoxData from '../../types/ProductBoxData';
  import Product from '../../types/Product';
  import type AccountOrderItem from '../../types/AccountOrderItem';

  @Options({
    name: 'product-name'
  })
  export default class ProductName extends Vue {
    @Prop({default: true})
    readonly productClickable: boolean;
    @Prop({required: true})
    readonly product: BasketItem | ProductBoxData | Product | AccountOrderItem;
  }
</script>
